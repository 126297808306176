<template>
    <div class="padding-around">
        <div class="container">
            <div class="row">
                <h4 v-if="create" class="col-12 mb-3">Добавить временной интервал</h4>
                <h4 v-else class="col-12 mb-3">Изменить временной интервал</h4>
                <b-form class="row g-1" @submit.prevent="saveTime">

                    <b-form-group label="Дата:" class="col-6" label-for="input-date_from">
                        <b-form-datepicker id="input-date_from"
                                v-model="date_from"
                                :value="date_from"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                locale="ru"
                                :state="dateTimeValidator"
                                required>
                        </b-form-datepicker>
                        <b-form-invalid-feedback>Дата не может быть пустой</b-form-invalid-feedback>
                    </b-form-group>

                  <b-form-group id="input-group-1" label="Время занятия:" class="col-6" label-for="input-time_from">
                    <input type="time" class="input-time_from form-control" v-model="time_from">
                    <b-form-invalid-feedback>Время не может быть пустым</b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group id="input-group-1" label="Время занятия:" class="col-6" label-for="input-time_to">
                    <input type="time" class="input-time_from form-control" v-model="time_to">
                    <b-form-invalid-feedback>Время не может быть пустым</b-form-invalid-feedback>
                  </b-form-group>

                    <div class="col-12">
                        <hr/>
                    </div>

                    <b-form-group class="col-4 text-left">
                        <b-button v-if="!!id" @click="removeTime" class="mr-2" variant="danger">Удалить</b-button>
                    </b-form-group>
                    <b-form-group class="col-8 text-right">
                        <b-button type="submit" class="mr-2" variant="success" :disabled="!canSend">Сохранить</b-button>
                        <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
                    </b-form-group>

                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapGetters } from 'vuex';
    import NormalTimePicker from '../../components/NormalTimePicker';

    export default {
        name: 'EditTimeModal',
        components: { NormalTimePicker },
        props: {
            create: {
                type: [Boolean],
                required: false,
                default: false
            },
            record: {
                type: [Object],
                required: true
            },
            actionHandler: {
                type: [Function],
                required: true
            },
            removeHandler: {
                type: [Function],
                required: true
            },
            teacherId: {
                type: Number,
                required: false,
                default: null
            }
        },
        data() {
            return {
                id: null,
                date_from: null,
                time_from: null,
                time_to: null
            };
        },
        filters: {
            toDateTitle(value) {
                return moment(value).format('DD MMM (dddd)');
            }
        },
        methods: {
            async saveTime() {
                const result = await this.actionHandler({
                    id: this.id,
                    date_from: moment(this.date_from).format('YYYY-MM-DD') + ' ' + this.timeFormatter(this.time_from) + ':00',
                    date_to: moment(this.date_from).format('YYYY-MM-DD') + ' ' + this.timeFormatter(this.time_to) + ':00',
                    teacher_id: this.teacherId
                });
                if (result) {
                    this.$modal.hideAll();
                }
            },
            removeTime() {
                this.removeHandler({
                    id: this.id
                });
                this.$modal.hideAll();
            },
            closeModal() {
              this.$modal.hideAll()
            },
            timeFormatter(value) {
                if (value.split(':').length > 1) {
                    let hour = value.split(':')[0];
                    hour = hour.length < 2 ? `0${hour}` : hour;
                    let min = value.split(':')[1];
                    min = min.length < 2 ? `0${min}` : min;
                    return `${hour}:${min}`;
                }
                return '';
            }
        },
        computed: {
            ...mapGetters({
                getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
            }),
            dateTimeValidator() {
                return !!this.date_from;
            },
            timeValidation() {
                return !!this.time_from || !!this.time_from;
            },
            canSend() {
                return this.dateTimeValidator
                    && this.timeValidation;
            }
        },
        created() {
            if (!this.create) {
                this.id = this.record.id;
                this.date_from = moment(this.record.date_from).format('YYYY-MM-DD') + ' 09:00:00';
                this.time_from = moment(this.record.date_from).format('HH:mm');
                this.time_to = moment(this.record.date_to).format('HH:mm');
            } else {
                this.date_from = moment().add(1, 'days').format('YYYY-MM-DD') + ' 09:00:00';
                this.time_from = '09:00';
                this.time_to = '18:00';
            }
        }
    }
</script>

<style scoped lang="scss">
.padding-around {
    padding: 14px 16px 0 14px;

}
</style>