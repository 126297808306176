<template>
    <div class="padding-around">
        <div class="container">
            <div class="row">
                <h4 class="title">Уроки на {{toDayTitle(date)}} с {{timeFrom}} по {{timeTo}}</h4>
                <div class="lesson-legend">
                    <LessonLegendComponent/>
                </div>
                <div class="lessons">
                    <div
                        class="lesson"
                        v-for='lesson in getLessons()'
                        :key="lesson.id"
                        :class="[lesson.status, lesson.lesson_type]"
                    >
                        <div class="item teacher">{{lesson.teacher_full_name}}</div>
                        <div class="item student">{{lesson.student_full_name}}</div>
                        <div class="item date">{{toDayRange(lesson)}}</div>
                        <div class="item duration">{{lesson.duration}} мин.</div>
                        <i @click="openLessonHandler(lesson)" class='fa fa-edit edit-lesson-icon' aria-hidden='true'></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import LessonLegendComponent from "@/components/LessonLegendComponent";

    export default {
        name: 'LessonListModal',
        components: {LessonLegendComponent},
        props: {
            date: {
                type: String,
                required: true
            },
            timeFrom: {
                type: String,
                required: true
            },
            timeTo: {
                type: String,
                required: true
            },
            lessons: {
                type: Array,
                required: true
            },
            openLessonHandler: {
                type: [Function],
                required: true
            },
        },
        data() {
            return {

            };
        },
        methods: {
            getLessons() {
                return this.lessons.slice().sort((a, b) => {
                    return new Date(a.date_from).getTime() - new Date(b.date_from).getTime()
                });
            },
            toDayTitle(value) {
                return moment(value).format('ddd DD MMM');
            },
            toDayRange(item) {
                const from = moment(item.date_from).format('HH:mm');
                const to = moment(item.date_to).format('HH:mm');
                return `${from} - ${to}`;
            },
            closeModal() {
              this.$modal.hideAll();
            }
        },
        created() {

        }
    }
</script>

<style scoped lang="scss">
.padding-around {
    padding: 20px;
}

.title {
    padding-bottom: 15px;
}

.lessons {
    max-height: 600px;
    overflow-y: auto;
    min-height: 300px;
    width: 100%;
}

.lesson-legend {
    padding: 15px 0;
}

.lesson {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-top: 5px solid #0EA804;
    background-color: #cae5ff;
    padding: 3px 8px;
    margin-bottom: 5px;
    .item {
        padding: 5px;
    }
    .teacher {
        width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .student {
        width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .date {
        text-align: center;
        width: 18%;
    }
    .duration {
        width: 15%;
    }
    .edit-lesson-icon {
        text-align: center;
        width: 7%;
        background-color: #3173b1;
        color: #fff;
        font-size: 16px;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
    }

    &.trial {
        border-top-color: $trial_lesson;
    }

    &.regular {
        border-top-color: $regular_lesson;
    }

    &.planned {
        background-color: $planned_lesson;
    }
    &.finished {
        background-color: $finish_lesson;
    }
    &.skipped {
        background-color: $skipped_lesson;
    }
    &.canceled {
        background-color: $canceled_lesson;
    }
}
</style>