<template>
    <div class='lessons-table'>
        <Preloader v-if='loading' class='overlay-small' />

        <table
            class='table table-hover'
        >
            <thead class='table_head'>
            <tr>
                <th>№</th>
                <th>Ученик</th>
                <th>Преподаватель</th>
                <th class='text-center'>Время московское</th>
            </tr>
            </thead>
            <tbody>
            <template v-if='schedule && schedule.length'>
                <tr
                    v-for='item in schedule'
                    :key='item.id'
                >
                    <td>{{ item.id }}</td>
                    <td>{{ item.student_full_name }}</td>
                    <td>{{ item.teacher_full_name }}</td>
                    <td class='text-center'><template v-if='item.date'>{{ mskTime(item.date) }}</template></td>
                </tr>
            </template>
            <tr v-else class='mt-4'>Нет данных для отображения</tr>
            </tbody>
        </table>

        <div
            v-if='pagination && pagination.current_page <= pagination.last_page && pagination.last_page > 1'
            class='py-1 overflow-auto'
        >
            <b-pagination
                class='paginator'
                :total-rows='pagination.total'
                :per-page='pagination.per_page'
                @page-click='handlePageSelect'
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment/moment';
import Preloader from '@/components/Preloader.vue';

export default {
    name: 'LessonsTable',
    components: { Preloader },
    data() {
        return {
            loading: false,
            schedule: null,
            pagination: null,
            filters: {
                page: 1,
            },
        };
    },
    async created() {
        await this.dataFetch();
    },
    methods: {
        async dataFetch() {
            this.loading = true;
            await this.fetchSchedule();
            this.loading = false;
        },
        async fetchSchedule() {
            try {
                const { data } = await this.$axios.get('/crm/lessons/schedule-table', {
                    params: {
                        page: this.filters.page,
                    },
                });
                this.schedule = data.data;
                this.pagination = data.meta;
            } catch (ex) {
                console.log('cant fetch data: ' + ex);
            }
        },
        async handlePageSelect(bvEvent, page) {
            if (page) {
                this.$set(this.filters, 'page', page);
                await this.dataFetch();
            }

            return false;
        },
        mskTime(value) {
            // с сервера уже приходит московское время
            return moment(value).format('ddd DD MMM YYYY H:mm:s');
        },
    },
};
</script>

<style lang='scss' scoped>
.lessons-table {
    &::v-deep {
        .page-item.active .page-link {
            z-index: 3;
            color: #fff;
            background-color: #3E86CA;
            border-color: #3E86CA;
        }
    }
}
</style>