<template>
    <div class='calendar__modal p-4'>
        <h4 class='font-weight-bold text-center'>{{$t('calendar.Добавить свободное время')}}</h4>
        <form class='dialog-form justify-content-center mb-4 mt-4'>
<!--            {{ minMaxTimePeriod }}-->
<!--            {{ minMaxTimePeriod }}&#45;&#45;{{ time_from }}&#45;&#45;{{ time_to }}-->
            <div class='dialog-group dialog-group__calendar flex-column w-100'>
                <div class="w-100 mb-4" v-if="!teacher">
                    <label>Преподаватель:</label>
                    <multiselect
                        class='d-block'
                        style='position: relative;'
                        v-model='selectedTeacher'
                        :options='teacherOptionList'
                        :multiple='false'
                        :close-on-select='false'
                        :clear-on-select='false'
                        :preserve-search='true'
                        selectedLabel=''
                        selectLabel=''
                        deselectLabel=''
                        placeholder='введите имя'
                        :tagable='true'
                        tagPosition='bottom'
                        label='text'
                        track-by='value'
                    >
                    </multiselect>
                </div>
                <div class='m-auto'>
                    <VCalendar v-model='calendarRange' is-range :min-date='new Date()' :locale='$i18n.locale' />
                </div>
                <div class='weekdays-container p-3'>
                    <div class='input-group'>
                        <label for='mon'>{{ $t('weekdays.пн') }}</label>
                        <input id='mon' v-model='weekdays.mon' type='checkbox'>
                    </div>
                    <div class='input-group'>
                        <label for='tue'>{{ $t('weekdays.вт') }}</label>
                        <input id='tue' v-model='weekdays.tue' type='checkbox'>
                    </div>
                    <div class='input-group'>
                        <label for='wed'>{{ $t('weekdays.ср') }}</label>
                        <input id='wed' v-model='weekdays.wed' type='checkbox'>
                    </div>
                    <div class='input-group'>
                        <label for='thu'>{{ $t('weekdays.чт') }}</label>
                        <input id='thu' v-model='weekdays.thu' type='checkbox'>
                    </div>
                    <div class='input-group'>
                        <label for='fri'>{{ $t('weekdays.пт') }}</label>
                        <input id='fri' v-model='weekdays.fri' type='checkbox'>
                    </div>
                    <div class='input-group'>
                        <label for='sat'>{{ $t('weekdays.сб') }}</label>
                        <input id='sat' v-model='weekdays.sat' type='checkbox'>
                    </div>
                    <div class='input-group'>
                        <label for='sun'>{{ $t('weekdays.вс') }}</label>
                        <input id='sun' v-model='weekdays.sun' type='checkbox'>
                    </div>
                </div>


                <div class='dialog-item w-100'>
                    <label for='timeFrom' class='mb-0'>{{ $t('calendar.Начало')}}</label>
                    <input type="time" class="input-time_from form-control" v-model="time_from">
                </div>
                <div class='dialog-item w-100'>
                    <label for='timeTo' class='mb-0'>{{ $t('calendar.Конец')}}</label>
                    <input type="time" class="input-time_from form-control" v-model="time_to">
                </div>
            </div>
        </form>

        <div class='text-center'>
            <button class='btn btn-cancel mr-1' @click="$modal.hide('LessonCalendarModal')">{{ $t('buttons.cancel') }}</button>
            <button :disabled='!validated' type='submit' class='btn btn-primary' @click='sendFormData'>{{ $t('buttons.save') }}
            </button>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import NormalTimePicker from '../NormalTimePicker';
import { mapGetters } from 'vuex';
import Multiselect from "vue-multiselect";

export default {
    name: 'FreeRangeModal',
    props: {
        payload: {},
        teacher: {
            type: Boolean,
            default: false
        },
        actionHandler: {
            type: Function,
            required: true
        },
    },
    components: {Multiselect, VCalendar, NormalTimePicker },
    data() {
        return {
            selectedTeacher: null,
            teacherOptionList: [],
            type: null,
            busyInput: null,
            calendarRange: {
                start: null,
                end: null,
            },
            saturday: false,
            saturdayOnly: false,
            sunday: false,
            sundayOnly: false,
            time_from: '09:00',
            time_to: '09:30',
            weekdays: {
                mon: false,
                tue: false,
                wed: false,
                thu: false,
                fri: false,
                sat: false,
                sun: false
            },
        };
    },
    computed: {
        ...mapGetters({
            pickedList: "scheduler/getDeleteList",
            emptyCells: "scheduler/getCellsDataMap",
            getTeachersOptionsList: 'teacher/getTeachersOptionsList',
        }),

        validated() {
            if(!this.teacher) {
                return (this.calendarRange.start && this.calendarRange.end) && this.time_from && this.time_to && this.selectedTeacher;
            }
            return (this.calendarRange.start && this.calendarRange.end) && this.time_from && this.time_to;
        },
        onlyWeekendMode() {
            return this.saturdayOnly || this.sundayOnly;
        },
        weekendPicked() {
            return this.saturday || this.saturdayOnly || this.sunday || this.sundayOnly;
        },
        payloadTime() {
            return {
                from: this.payload.timePeriod[0],
                to: this.payload.timePeriod[1],
            };
        },
        // formattedDate() {
        //     const [day, month, year] = this.payload.date[3].split('.');
        //     moment.locale('ru');
        //     return new moment(month + '.' + day + '.' + year);
        // },
        displayedDate() {
            return new moment(this.formattedDate).locale('ru').format('dddd DD MMMM YYYY');
        },
        showBusyInput() {
            return this.type === 'busyUntil';
        },
    },
    methods: {
        minMaxTimePeriod() {
            let out = [];
            for (const key in this.pickedList) {
                out.push(key)
            }
            out = out.sort()
            let lasElem = out.length ? this.pickedList[out[out.length - 1]].caption : null;
            lasElem = ((lastElem) => {
                if (!lastElem) {
                    return null;
                }
                let hours = lastElem.split(':')[0]
                let minutes = lastElem.split(':')[1]

                if (minutes === '30') {
                    hours = (parseInt(hours) + 1) % 24
                    minutes = "00"
                } else {
                    minutes = "30"
                }

                return hours + ":" + minutes;
            })(lasElem)

            const res = out.length ? [this.pickedList[out[0]].caption, lasElem].sort() : null;
            if (res) {
                const tmp = [];
                for (const item of res) {
                    tmp.push(item)
                }
                return [tmp[0], tmp[tmp.length - 1]].sort()
            }
            return res
        },
        minMaxEmptyPeriod() {
            let out = [];
            for (const key in this.emptyCells) {
                out.push(key)
            }
            out = out.sort()
            const res = out.length ? [this.emptyCells[out[0]].from, this.emptyCells[out[out.length - 1]].to].sort() : null;
            if (res) {
                const tmp = [];
                for (const item of res) {
                    tmp.push(item)
                }
                return [tmp[0], tmp[tmp.length - 1]].sort()
            }
            return res


        },
        setDefaultTime() {
            const busyMinMax = this.minMaxTimePeriod()
            const emptyMinMax = this.minMaxEmptyPeriod()
            const minMax = [];
            if (busyMinMax && busyMinMax.length && emptyMinMax && emptyMinMax.length) {
                minMax.push(...busyMinMax, ...emptyMinMax)
                minMax.sort()
                this.time_from = minMax ? minMax[0] : null;
                this.time_to = minMax ? minMax[minMax.length - 1] : null;
                return;
            }

            if (busyMinMax && busyMinMax.length) {
                minMax.push(...busyMinMax)
                this.time_from = minMax ? minMax[0] : null;
                this.time_to = minMax ? minMax[1] : null;
                return;
            }
            minMax.push(...emptyMinMax)
            this.time_from = minMax ? minMax[0] : null;
            this.time_to = minMax ? minMax[1] : null;
        },
        makeWeekDayPayload() {
            let dayCounter = 1;
            const out = [];
            for (const key in this.weekdays) {
                if (this.weekdays[key]) {
                    out.push(dayCounter)
                }
                dayCounter++;
            }
            return out;
        },
        async sendFormData() {
            try {
                const request_id = this.teacher ? (this.$store.state.user.user_id ?? this.$store.state.user.teacher_profile.id) : this.selectedTeacher.value
                const payload_date = this.showBusyInput ? this.calendarRange.start : this.formattedDate;
                const { data } = await this.$axios.post(`/api/teacher/${request_id}/schedule/add`, {
                    date: moment(this.calendarRange.start).format('DD.MM.YYYY'),
                    date_to: moment(this.calendarRange.end).format('DD.MM.YYYY'),
                    from: this.time_from,
                    to: this.time_to,
                    weekdays: this.makeWeekDayPayload()
                    // date_range: this.showBusyInput,
                });
                if (data && data.success) {
                    this.actionHandler()
                    this.showNotify('Свободный период успешно создан')
                    this.$modal.hide('LessonCalendarModal');
                    this.$emit('scheduleChanged');
                }
            } catch (e) {
              let errMsg = `Ошибка создания cвободного периода.`;
              if (e.response && e.response.data.error) {
                errMsg = e.response.data.error;
              }
              else if (e.response && e.response.data.message) {
                errMsg = e.response.data.message;
              }
              this.showErrorNotify(errMsg)
            }
        },
        showNotify(text) {
            this.$toasted.success(text, {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
        showErrorNotify(text) {
            this.$toasted.error(text, {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
    },
    mounted() {
        this.teacherOptionList = this.getTeachersOptionsList.filter(item => item.value > -1);
        this.setDefaultTime()
    }
};
</script>

<style lang='scss' scoped>
.dialog {
    &-form {
        display: flex;
        flex-wrap: wrap;
    }
    &-group {
        display: flex;
        &__calendar {
            //margin-right: 15px;
            padding: 0 25px;
        }

    }
    &-item {
        label {
            font-size: 12px;
        }
    }
}

.input-time {
    border: 1px solid #eee;
    padding: 2px 8px;
    border-radius: 2px;
}

.btn {
    width: 150px;
}

.btn-cancel {
    color: #000;
    background: #eee;
}

.calendar__header {
    margin: 0;
}

.calendar__modal_line {
    display: flex;
    align-items: center;
    padding: 20px 0;
    justify-content: center;
    flex-direction: column;
}

.calendar__modal_group {
    display: flex;
    align-items: center;
    padding-right: 10px;

    label {
        margin: 0;
        margin-right: 5px;
    }
}

.weekend-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
        margin: 0;
    }

    input {
        margin-left: 3px;
        margin-right: 5px;
    }
}

.form-label {
    font-size: 14px;
    font-weight: 300;
}

.validation-error {
    color: #ec3b3b;
    font-size: 10px;
}
.weekdays-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .input-group {
        width: 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>