<template>
    <div class="padding-around">
        <div class="container">
            <div class="row">
                <h4 class="col-12 mb-3">Детали временного интервала</h4>
                <div class="col-12 list-container">
                  <b-table :items="details" :fields="[]"></b-table>
                </div>
                <div class="col-12 mb-4 text-right">
                    <b-button type="reset" variant="primary" @click="closeModal">Закрыть</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TimeLineModal',
        props: {
            details: {
                type: Array,
                required: true
            },
        },
        data() {
            return {

            };
        },
        methods: {
            closeModal() {
              this.$modal.hideAll();
            }
        },
        created() {

        }
    }
</script>

<style scoped lang="scss">
.padding-around {
    padding: 14px 16px 0 14px;
}
.list-container {
    max-height: 600px;
    overflow-y: auto;
}
</style>