<template>
    <div class='leads'>
        <div class='filter__container'>
            <div class='row mb-3 justify-content-between'>
                <div class='input-group col-12 col-xl-6'>
                    <input type='search' class='form-control' v-model='searchWord' id='search-input'>
                    <span class='input-group-append'>
                        <button type='button' class='btn btn-default' @click='search'>Искать</button>
                    </span>
                </div>
                <div class='col-6 col-xl-4 mt-3 mt-xl-0 d-flex justify-content-start justify-content-xl-end'>
                    <Switcher v-model='showFilter' label='показать фильтры' />
                </div>
                <div class='col-6 col-xl-2 mt-3 mt-xl-0 text-right'>
                    <button class='btn btn-primary' @click.prevent='createNewLead'>Создать заявку</button>
                </div>
                <div class="exports col-12 mt-3">
                  <b-link class="btn btn-success" :href="getLinkExcelDownload">Экспортировать в Excel</b-link>
                </div>
            </div>

            <div v-if='showFilter' class='filter-list py-3 mb-3 widget'>
                <div class='row'>
                    <div class='col-12'>
                        <h5 class='block font-weight-bold' href=''>Фильтры <i class='fa fa-sliders'></i></h5>
                    </div>
                    <div class='col-12 col-xl-3'>
                        <div class='row'>
                            <p class='col-12 font-weight-bold m-0'>Дата занятия</p>
                            <div class='col-6'>
                                <VCalendar mode='date' v-model='filters.date_from'>
                                    <template v-slot='{ inputValue, inputEvents }'>
                                        <div class='position-relative'>
                                            <input id='date_from'
                                                   class='form-control'
                                                   :value='inputValue'
                                                   v-on='inputEvents'
                                                   placeholder='показывать c'
                                            >
                                            <a
                                                class='clear-input'
                                                v-show='filters.date_from'
                                                @click='clearFilter("date_from")'
                                            >
                                                <i class='fa fa-close'></i>
                                            </a>
                                        </div>
                                    </template>
                                </VCalendar>
                            </div>
                            <div class='col-6'>
                                <VCalendar mode='date' v-model='filters.date_to'>
                                    <template v-slot='{ inputValue, inputEvents }'>
                                        <div class='position-relative'>
                                            <input
                                                id='date_to'
                                                class='form-control'
                                                :value='inputValue'
                                                v-on='inputEvents'
                                                placeholder='показывать по'
                                            >
                                            <a
                                                v-show='filters.date_to'
                                                class='clear-input'
                                                @click='clearFilter("date_to")'
                                            >
                                                <i class='fa fa-close'></i>
                                            </a>
                                        </div>
                                    </template>
                                </VCalendar>
                            </div>
                        </div>
                    </div>

                    <div class='col-12 col-xl-5'>
                        <div class='row'>
                            <p class='col-12 font-weight-bold m-0'>Занятия сегодня</p>
                            <div class='col-4 align-items-center h-100'>
                                <label for='scheduled_lessons' class='m-0 pr-2'>Назначенные</label>
                                <input
                                    id='scheduled_lessons'
                                    type='checkbox'
                                    v-model='filters.scheduled_lessons'
                                    @change.prevent='handleCheckBoxFilter("scheduled_lessons")'
                                >
                            </div>
                            <div class='col-4 align-items-center h-100'>
                                <label for='passed_lessons' class='m-0 pr-2'>Прошедшие</label>
                                <input
                                    id='passed_lessons'
                                    type='checkbox'
                                    v-model='filters.passed_lessons'
                                    @change.prevent='handleCheckBoxFilter("passed_lessons")'
                                >
                            </div>
                            <div class='col-4 align-items-center h-100'>
                                <label for='skipped_lessons' class='m-0 pr-2'>Пропущенные</label>
                                <input
                                    id='skipped_lessons'
                                    type='checkbox'
                                    v-model='filters.skipped_lessons'
                                    @change.prevent='handleCheckBoxFilter("skipped_lessons")'
                                >
                            </div>
                        </div>
                    </div>
                    <div class='col-12 col-xl-4 mt-xl-0 mt-3'>
                        <div class='row'>
                            <p class='col-12 font-weight-bold m-0'>Показывать</p>
                            <div class='col-6 align-items-center h-100'>
                                <label for='utm_mark' class='m-0 pr-2'>Заявки по UTM</label>
                                <input
                                    id='utm_mark'
                                    type='checkbox'
                                    v-model='filters.utm'
                                    @change.prevent='handleCheckBoxFilter("utm")'
                                >
                            </div>
                            <div class='col-6 align-items-center h-100'>
                                <label for='trashed_mark' class='m-0 pr-2'>Удаленные заявки</label>
                                <input
                                    id='trashed_mark'
                                    type='checkbox'
                                    v-model='filters.trashed'
                                    @change.prevent='handleCheckBoxFilter("trashed")'
                                >
                            </div>
                        </div>
                    </div>
                    <div class='col-12 mt-3'>
                        <div class='row'>
                            <div class='col-6 mb-3'>
                              <p class='font-weight-bold m-0'>Статусы</p>
                                <vue-multiselect
                                    v-model='filters.status'
                                    :options='leadStatuses'
                                    :multiple='true'
                                    label='text'
                                    track-by='value'
                                >
                                    <template slot='tag' slot-scope='props'>
                                        <b-badge class='mr-1' :variant='props.option.badge.css' pill>
                                            {{ props.option.badge.title }} <a @click='props.remove(props.option)'><i
                                            class='fa fa-close'></i></a>
                                        </b-badge>
                                    </template>
                                    <template slot='option' slot-scope='props'>
                                        <b-badge :variant='props.option.badge.css' pill>{{ props.option.badge.title }}
                                        </b-badge>
                                    </template>
                                </vue-multiselect>
                            </div>
                            <div class="col-6 mb-3">
                              <p class='font-weight-bold m-0'>Возрастные группы</p>
                                <VueMultiselect
                                    class='custom__multiselect mr-3'
                                    :multiple='true'
                                    :close-on-select='false'
                                    :clear-on-select='false'
                                    :preserve-search='true'
                                    selectedLabel='Выбран'
                                    selectLabel='Добавить'
                                    deselectLabel='Убрать'
                                    placeholder='Выберите возрастную группу'
                                    :tagable='true'
                                    tagPosition='bottom'
                                    label='text'
                                    track-by='value'
                                    :options='getAgeGroups'
                                    v-model='filters.age_group'
                                >
                                    <template #noOptions>Нет данных</template>
                                    <template #noResult>Ничего не найдено</template>
                                </VueMultiselect>
                            </div>
                            <div class="col-6 mb-3">
                              <p class='font-weight-bold m-0'>Источники</p>
                              <VueMultiselect
                                  class='custom__multiselect mr-3'
                                  :multiple='true'
                                  :close-on-select='false'
                                  :clear-on-select='false'
                                  :preserve-search='true'
                                  selectedLabel='Выбран'
                                  selectLabel='Добавить'
                                  deselectLabel='Убрать'
                                  placeholder='Выберите источник'
                                  :tagable='true'
                                  tagPosition='bottom'
                                  label='text'
                                  track-by='value'
                                  :options='getLeadProviders'
                                  v-model='filters.providers'
                              >
                                <template #noOptions>Нет данных</template>
                                <template #noResult>Ничего не найдено</template>
                              </VueMultiselect>
                            </div>
                          </div>
                    </div>
                    <div class='col-12'></div>
                    <div class='col-12 text-right'>
                        <a @click='clearFilter("all")'>Очистить фильтры</a>
                    </div>
                </div>
            </div>
        </div>

        <div class='leads-list widget'>
            <div class='table_wrapper'>
                <pagination-counter class="text-right my-2" :meta="getPageMeta"/>
                <LeadsTable
                    v-if='getLeads'
                    :leads='getLeads'
                    :clone-lead='cloneLead'
                    :cancel-promo='cancelPromo'
                    :restore-lead='restoreLead'
                    :delete-row='deleteRow'
                    :last-contact-save='lastContactSaveFunc'
                />
            </div>
        </div>

        <div
            class='overflow-auto d-flex align-items-center justify-content-between'
             v-if="getPageMeta && getPageMeta.last_page > 1"
        >
            <b-pagination
                class='paginator mb-0'
                v-model='currentPage'
                :total-rows='getPageMeta.total'
                :per-page='getPageMeta.per_page'
                aria-controls='my-table'
            />
            <pagination-counter class="mr-4" :meta="getPageMeta"/>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { leadStatuses } from '@/helpers/badges';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';
import { debounce } from 'debounce';
import notificationMixin from '@/mixins/notificationMixin';
import VCalendar from 'v-calendar/lib/components/date-picker.umd';
import VueMultiselect from 'vue-multiselect';
import DeleteModal from '@/components/Modal/DeleteModal';
import Switcher from '@/components/Switcher';
import LeadsTable from '@/components/Leads/LeadsTable';
import Preloader from '@/components/Preloader';
import PaginationCounter from "@/components/Pagination/PaginationCounter.vue";

export default {
    name: 'Leads',
    mixins: [notificationMixin],
    components: {
      PaginationCounter,
        Preloader,
        Switcher,
        LeadsTable,
        VCalendar,
        VueMultiselect,
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_API_BASE_URL,
            routerParams: { ...this.$route.query },
            currentPage: this.$route.query.page || 1,
            rows: 125,
            perPage: 25,
            searchWord: this.$route.query.search || '',
            debounceCooldown: null,
            showFilter: false,
            filters: {
                date_from: null,
                date_to: null,
                status: null,
                age_group: null,
                providers: null,
                trashed: this.$route.query.trashed || false,
                utm: this.$route.query.with_utm || false,
                passed_lessons: null,
                skipped_lessons: null,
                scheduled_lessons: null,
            },
        };
    },
    async created() {
        if (Object.keys(this.$route.query).filter(item => item !== 'search' || item !== 'page').length > 0) {
            this.showFilter = true;
            this.fillFilters();
        }
        await this.fetchLeads();
        dayjs.extend(duration);
        dayjs.locale(ru);
    },
    computed: {
        ...mapGetters({
            getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
            getLeads: 'leads/getLeads',
            getPageMeta: 'leads/getPageMeta',
            getStatuses: 'common/getLeadStatuses',
            getAgeGroups: 'common/getAgeGroups',
            getLeadProviders: 'common/getLeadProviders',
        }),
        getPaginationCounterText() {
          let to = 0

          if (this.getPageMeta.to) {
            to = this.getPageMeta.to
          }

          if (this.getPageMeta.total < this.getPageMeta.per_page) {
            return `0-${to} из ${this.getPageMeta.total}`
          }
          return `${to - this.getPageMeta.per_page}-${this.getPageMeta.to} из ${this.getPageMeta.total}`
        },
        getLinkExcelDownload() {
          let queries = '';

          for (const [key, value] of Object.entries({ ...this.$route.query })) {
            if (value !== null) {
              if (queries === '') {
                queries += `?${key}=${value}`
                continue
              }
              queries += `&${key}=${value}`
            }
          }

          return `${this.baseUrl}/crm/leads/export-excel${queries}`
        },
        leadStatuses() {
            return Object.keys(this.getStatuses).map(this.formatLeadItem);
        },
    },
    watch: {
        currentPage(value) {
            this.routerParams['page'] = value;
            this.updateRouter();
            this.fetchLeads();
        },
        searchWord(value) {
            this.debounceSearch();
        },
        'filters.date_from': async function(val, oldVal) {
            this.routerParams['date_from'] = val ? dayjs(val).format('YYYY-MM-DD') : '';
            this.updateRouter();
            await this.fetchLeads();
        },
        'filters.date_to': async function(val, oldVal) {
            this.routerParams['date_to'] = val ? dayjs(val).format('YYYY-MM-DD') : '';
            this.updateRouter();
            await this.fetchLeads();
        },
        'filters.status': async function(val, oldVal) {
            this.routerParams['status'] = val ? val.map(item => item.value) : val;
            this.updateRouter();
            await this.fetchLeads();
        },
        'filters.age_group': async function(val, oldVal) {
            this.routerParams['age_group'] = val ? val.map(item => item.value) : val;
            this.updateRouter();
            await this.fetchLeads();
        },
        'filters.providers': async function(val, oldVal) {
            this.routerParams['providers'] = val ? val.map(item => item.value) : val;
            this.updateRouter();
            await this.fetchLeads();
        },
    },
    methods: {
        ...mapActions({
            loadLeads: 'leads/loadLeads',
            findLeads: 'leads/findLeads',
            deleteRecord: 'leads/deleteRecord',
            cancelPromoLesson: 'leads/cancelPromoLesson',
            restoreLeadData: 'leads/restoreLead',
            cloneLeadData: 'leads/cloneLead',
            deleteRowFromState: 'leads/deleteRow',
        }),
        formatLeadItem(key) {
            return {
                text: this.getStatuses[key].title,
                value: parseInt(key),
                badge: leadStatuses[key],
            };
        },
        createNewLead() {
            this.$router.push({ name: 'NewLead' });
        },
        deleteRow(id, name, index) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: async () => {
                        try {
                            const { data } = await this.deleteRecord(id);
                            this.responseNotify(data, 'Заявка удалена', () => this.fetchLeads());
                        } catch (e) {
                            if (e.response && e.response.data) {
                                this.responseNotify(e.response.data);
                            }
                            console.error(e);
                        }

                    },
                }, { name: 'DeleteModal' },
            );
        },
        cancelPromo(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    title: 'Вы действительно хотите отменить?',
                    recordId: id,
                    recordName: 'Пробный урок',
                    callback: this.fetchLeads,
                    deleteHandler: this.cancelPromoLesson,
                }, { name: 'DeleteModal' });
        },
        loadMore() {
            this.loadLeads({ page: this.getNextPage });
        },
        async handleCheckBoxFilter(type) {
            if (this.searchWord.length > 0) this.searchWord = null;
            this.routerParams[type] = this.filters[type] ? 1 : 0;
            this.updateRouter();
            await this.fetchLeads();
        },
        async handleUTM(e) {
            if (this.searchWord.length > 0) this.searchWord = null;
            this.routerParams['utm'] = this.filters.utm ? 1 : 0;
            this.updateRouter();
            await this.fetchLeads();
        },
        async searchByEnter(e) {
            if (this.searchWord.length > 2) {
                if (e.keyCode == 13) {
                    await this.findLeads(this.searchWord);
                }
            }
        },
        async search() {
            if (this.searchWord.length > 2) {
                await this.findLeads(this.searchWord);
            }
        },
        async restoreLead(id) {
            if (typeof id === 'number') {
                await this.restoreLeadData(id);
                await this.fetchLeads();
            }
        },
        async cloneLead(id, lead) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: lead.student_full_name,
                    title: 'Клонировать заявку №' + id + '?',
                    callback: this.fetchLeads,
                    deleteHandler: async () => {
                        if (typeof id === 'number') {
                            const result = await this.cloneLeadData(id);
                            if (result && result.status === 'success') {
                                this.$toasted.success('Заявка продублирована!', {
                                    position: 'bottom-right',
                                    action: {
                                        text: 'Закрыть',
                                        onClick: (e, toastObject) => {
                                            toastObject.goAway(0);
                                        },
                                    },
                                });
                                await this.fetchLeads();
                            } else {
                                this.$toasted.error('Ошибка. Не удается клонировать заявку.', {
                                    position: 'bottom-right',
                                    action: {
                                        text: 'Закрыть',
                                        onClick: (e, toastObject) => {
                                            toastObject.goAway(0);
                                        },
                                    },
                                });
                            }
                        }
                    },
                }, { name: 'DeleteModal' });
        },
        updateRouter() {
            this.$router.push({ name: 'LeadsPage', query: this.routerParams });
        },
        fetchLeads: debounce(async function() {
            await this.loadLeads(this.$route.query);
        }, 300),
        debounceSearch: debounce(async function(e) {
            this.routerParams['search'] = this.searchWord;
            this.updateRouter();
            await this.fetchLeads();
        }, 300),
        setCheckboxFilterVal(key) {
            if (parseInt(this.$route.query[key]) === 0 || parseInt(this.$route.query[key])) {
                this.filters[key] = parseInt(this.$route.query[key]);
            } else {
                this.filters[key] = false;
            }
        },
        fillFilters() {
            const queryKeys = Object.keys(this.$route.query);
            const checkBoxFilters = ['utm', 'trashed', 'skipped_lessons', 'passed_lessons', 'scheduled_lessons'];
            for (const key of queryKeys) {
                if (checkBoxFilters.includes(key)) {
                    this.setCheckboxFilterVal(key);
                }

                if (key === 'date_from' || key === 'date_to') {
                    this.filters[key] = this.$route.query[key];
                }

                if (key === 'status') {
                    if (this.$route.query.status && !Array.isArray(this.$route.query.status)) {
                        if (parseInt(this.$route.query.status) || parseInt(this.$route.query.status) === 0) {
                            this.filters.status = [this.formatLeadItem(this.$route.query.status)];
                            return;
                        }
                    }
                    this.filters.status = this.$route.query.status.map(key => this.formatLeadItem(key));
                }
            }
        },
        async clearFilter(val_name) {
            if (val_name === 'all') {
                for (const key in this.filters) {
                    this.filters[key] = null;
                }
                this.updateRouter();
                return;
            }
            this.filters[val_name] = null;
        },
        async lastContactSaveFunc(id, form) {
            try {
                const { data } = await this.$axios.post(`/api/lead/${id}/contact/update`, form);
                this.notify(data);
            } catch (e) {
                if (e.response && e.response.data) {
                    this.notify(e.response.data);
                }
                console.error(e);
            }
        },
        notify(data) {
            this.responseNotify(data, 'Данные обновленны', async () => {
                this.$modal.hideAll();
                await this.fetchLeads();
            });
        },
    },
};
</script>

<style lang='scss' scoped>
.badge-copy {
    background: rgba(#000, .1);
}

.btn .glyphicon {
    top: 0 !important;
}

.table_wrapper {
    overflow-x: auto;
    position: relative;
}

.table_head, th {
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
}

.actions {
    max-width: 100px;
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-button {
    font-weight: 700;
    color: black;
    background: #fff;
    padding: 0 20px;
    border-radius: 5px 5px 0 0;
    position: relative;
    z-index: 1000;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -30px;
        left: 0;
        background: #fff;
        width: 100%;
        height: 30px;
    }
}

.clear-input {
    position: absolute;
    right: 10px;
    color: lighten(red, 20);
    top: 50%;
    transform: translateY(-50%);
}

.badge {
    border-radius: 5px
}
</style>