<template>
    <div>
        <div>
            <h4 class='mb-4 h4-title'>
                Занятия
            </h4>
        </div>

        <div class='widget no-legend-border'>
            <div class='d-flex justify-content-end'>
                <b-button
                    type='button'
                    class='mr-2'
                    :variant="currentViewType('schedule') ? 'primary' : 'outline-primary'"
                    @click="changeViewType('schedule')"
                >
                    Календарь
                </b-button>
                <b-button
                    type='button'
                    :variant="currentViewType('table') ? 'primary' : 'outline-primary'"
                    @click="changeViewType('table')"
                >
                    Таблица
                </b-button>
            </div>

            <LessonsTable v-if="viewType === 'table'" class='mt-4'/>

            <div v-else-if="viewType === 'schedule'">
                <div class='row align-items-center'>
                    <div class='col-sm-4 teacher-wrap text-left'>
                        <span>Преподаватель&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <multiselect
                            class="custom__multiselect"
                            :options="teachersOptionsList"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            selectedLabel="Выбран"
                            selectLabel="нажмите чтобы выбрать"
                            deselectLabel="нажмите чтобы убрать"
                            placeholder="Введите имя или фамилию"
                            :tagable="true"
                            tagPosition="bottom"
                            label="text"
                            track-by="value"
                            :value="selectedTeacherIds"
                            @input="chooseTeacherHandler"
                        >
                        </multiselect>
                    </div>
                    <div class='col-sm-4 teacher-wrap text-left'>
                        <span>Ученик</span>
                        <multiselect
                            class="custom__multiselect"
                            :options="studentsOptionsList"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            selectedLabel="Выбран"
                            selectLabel="нажмите чтобы выбрать"
                            deselectLabel="нажмите чтобы убрать"
                            placeholder="Введите имя или фамилию"
                            :tagable="true"
                            tagPosition="bottom"
                            label="text"
                            track-by="value"
                            :value="selectedStudentsIds"
                            @search-change="searchStudentByTerm"
                            @input="chooseStudentHandler"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class='row align-items-center'>
                    <div class='col-4 text-left'>
                        <b-form-group
                            label='Пол преподавателя:'
                            label-cols-sm="4"
                            label-align-sm='left'
                            class='mb-0'
                            style='border: none'
                            v-slot='{ ariaDescribedby }'
                        >
                            <b-form-radio-group
                                name='gender_radios'
                                class='pt-2'
                                :options='getGenderList'
                                :aria-describedby='ariaDescribedby'
                                :checked="gender"
                                @change="handleGenderChange"
                            />
                        </b-form-group>
                    </div>

                    <div class='col-4 text-left'>
                        <b-form-group
                            label='Язык преподавания:'
                            label-cols-sm="4"
                            label-align-sm='left'
                            class='mb-0'
                            style='border: none'
                            v-slot='{ ariaDescribedby }'
                        >
                            <b-form-radio-group
                                name='language_radios'
                                class='pt-2'
                                :options='getLanguageList'
                                :aria-describedby='ariaDescribedby'
                                :checked="language"
                                @change="handleLanguageChange"
                            />
                        </b-form-group>
                    </div>
                    <div class='col-2 text-left'>
                        <b-form-checkbox
                            id="checkbox-view-canceled-lesson"
                            v-model="isShowCanceledLesson"
                            name="checkbox-view-canceled-lesson"
                            :checked="isShowCanceledLesson"
                            @change="handleShowCanceledLessonChange"
                        >
                            Показать отмененные уроки
                        </b-form-checkbox>
                    </div>
                    <div class='col-2 text-left'>

                    </div>
                </div>
                <hr/>

                <NewLessonsCalendar/>

            </div>
        </div>


    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CreateLessonModal from '@/components/Modal/CreateLessonModal';
import TimeLineModal from '@/components/Modal/TimeLineModal';
import EditLessonModal from '@/components/Modal/EditLessonModal';
import WeeksPicker from '@/components/WeeksPicker/WeeksPicker';
import Multiselect from 'vue-multiselect';
import EditTimeModal from '@/components/Modal/EditTimeModal';
import EditTimeManagerModal from '@/components/Modal/EditTimeManagerModal';
import LessonsTable from '@/components/admin/lessons/LessonsTable.vue';
import NewLessonsCalendar from "@/components/NewLessonsCalendar/NewLessonsCalendar";

export default {
    name: 'LessonsCalendar',
    components: {NewLessonsCalendar, LessonsTable, WeeksPicker, Multiselect},
    data: function () {
        return {
            isShowCanceledLesson: false,
            gender: 0,
            language: 0,
            h10min: 10, // высота 10 минут в пикселях
            teachersOptionsList: [],
            studentsOptionsList: [],
            selectedTeacherIds: [], // список выбранных преподавателей
            selectedStudentsIds: [], // список выбранных учеников
            prevSelectedTeacherIds: [], // прошлый выбор
            currentDate: new Date(),
            viewType: 'schedule', // table
        };
    },
    filters: {
        toDayTitle(value) {
            return moment(value).format('ddd DD MMM');
        },
        toDayRange(item) {
            const from = moment(item.date_from).format('HH:mm');
            const to = moment(item.date_to).format('HH:mm');
            return `${from} - ${to}`;
        },
        suffix(value) {
            const words = value.split(' ');
            let cnt = parseInt(words[0]);
            let n = Math.abs(cnt) % 100;
            let n1 = n % 10;
            if (n > 10 && n < 20) {
                return value + 'ей';
            }
            if (n1 > 1 && n1 < 5) {
                return value + 'я';
            }
            if (n1 === 1) {
                return value + 'ь';
            }
        },
    },
    computed: {
        ...mapGetters({
            getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
            getTeachersOptionsList: 'teacher/getTeachersOptionsList',
            getLessonsSchedule: 'lessons/getLessonsSchedule',
            getAvailableDaysShedule: 'lessons/getAvailableDaysShedule',
            getDayHoursRange: 'lessons/getDayHoursRange',
            getGenderList: 'common/getGenderList',
            getLanguageList: 'common/getLanguageList',
            getScheduleRefresh: 'scheduler/getScheduleRefresh',
        }),
        shedule: {
            get() {
                // комбинируем данные
                // TODO сделать доп роут который отдает готовую сборку
                const zeroPoint = this.getDayHoursRange.dayStartHour * 60;
                const schedule = {};
                for (const item of this.getLessonsSchedule) {
                    schedule[item.day] = {...item};
                    for (const lesson of schedule[item.day].items) {
                        lesson.isLesson = true;
                    }
                }
                for (const item of this.getAvailableDaysShedule) {
                    if (typeof (schedule[item.day]) !== 'undefined') {
                        schedule[item.day].items = [...schedule[item.day].items, ...item.items];
                    } else {
                        schedule[item.day] = {...item};
                    }
                }
                // рассчитываем позицию и высоту элементов
                for (const key of Object.keys(schedule)) {
                    if (!schedule[key].items) continue;
                    for (const item of schedule[key].items) {
                        const from = parseInt(moment(item.date_from).format('H')) * 60 + parseInt(moment(item.date_from).format('m')); // hour * 60 + minutes = total minutes
                        const to = parseInt(moment(item.date_to).format('H')) * 60 + parseInt(moment(item.date_to).format('m'));
                        item.position = (from - zeroPoint) + 'px';
                        item.height = (to - from) + 'px';
                    }
                }
                return Object.values(schedule);
            },
            set(val) {
                return val;
            },
        },

        // построение временной шкалы на основе часа когда начинается рабочий день и когда заканчивается
        timeLine: function () {
            const start = this.getDayHoursRange.dayStartHour;
            const end = this.getDayHoursRange.dayEndHour;
            if (start > end) return [];
            let index = 0;
            let now = start;
            const timeline = [];
            while (now <= end) {
                timeline.push({
                    // час
                    caption: (`${now}`.length > 1 ? now : `0${now}`) + ':00', // добавляем ноль в начале, если нужно
                    // позиция
                    position: (this.h10min * 6 * index) + 'px',
                    // высота
                    height: (this.h10min * 6) + 'px',
                });
                now += 1;
                index += 1;
            }
            return timeline;
        },
        dayHeight: function () {
            const start = this.getDayHoursRange.dayStartHour;
            const end = this.getDayHoursRange.dayEndHour;
            if (start > end) return '600px';
            let number = (end - start + 1) * (this.h10min * 6);
            return `${number}px`;
        },
    },
    methods: {
        ...mapActions({
            fetchTeachersOptions: 'teacher/fetchTeachersOptions',
            fetchStudentOptions: 'students/fetchStudentOptions',
            fetchLessonsSchedule: 'lessons/fetchLessonsSchedule',
            fetchAvailableDaysSchedule: 'lessons/fetchAvailableDaysSchedule',
            updateLessonDetails: 'lessons/updateLessonDetails',
            createLesson: 'lessons/createLesson',
            removeLesson: 'lessons/removeLesson',
            saveCalendarTime: 'lessons/saveCalendarTime',
            removeCalendarTime: 'lessons/removeCalendarTime',
            setScheduleRefresh: 'scheduler/scheduleRefresh',
            updateFilterByKey: 'lessonsSearch/updateFilterByKey',
        }),
        ...mapMutations({
            setLessonsSchedule: 'lessons/setLessonsSchedule',
            setAvailableDaysSchedule: 'lessons/setAvailableDaysSchedule',
            setFilterByKey: 'lessonsSearch/setFilterByKey'
        }),
        async searchStudentByTerm(search) {
            try {
                const students = await this.fetchStudentOptions({search});
                this.studentsOptionsList = students.map(item => ({
                    text: item.text,
                    value: item.value
                }));
            } catch (ex) {
                console.log('cant find students by term: ', ex);
            }
        },
        loadTeacherOptions() {
            return this.fetchTeachersOptions({
                gender: this.gender,
                language: this.language
            });
        },
        createTitleLabel(item) {
            return 'Учитель: ' + item.teacher.full_name + ' Студент: ' + item.student.full_name + ', ' + item.student.age +
                ' лет, ' + item.status_title;
        },

        async handleGenderChange(val) {
            this.updateFilterByKey({key: 'gender', value: val});
            this.gender = val;
            await this.loadTeacherOptions();
        },
        async handleLanguageChange(val) {
            this.updateFilterByKey({key: 'language', value: val});
            this.language = val;
            await this.loadTeacherOptions();
        },
        handleShowCanceledLessonChange(val) {
            this.updateFilterByKey({key: 'isShowCanceledLesson', value: val});
            this.isShowCanceledLesson = val;
        },

        chooseTeacherHandler(teachers, old) {
            this.updateFilterByKey({key: 'teachers_ids', value: teachers.map(item => item.value)});
            this.selectedTeacherIds = teachers;
            this.shedule = [];
            this.loadShedule();
        },
        chooseStudentHandler(students) {
            this.updateFilterByKey({key: 'students_ids', value: students.map(item => item.value)});
            this.selectedStudentsIds = students;
            this.shedule = [];
            this.loadShedule();
        },
        onChangeDatePeriod(event) {
            this.updateFilterByKey({key: 'currentDate', value: event.date})
            this.currentDate = event.date;
            this.shedule = [];
            this.loadShedule();
        },
        async loadShedule() {
            if (!this.selectedTeacherIds.length) {
                this.selectedTeacherIds = [];
            }
            if (!this.selectedStudentsIds.length) {
                this.selectedStudentsIds = [];
            }
            const date = moment(this.currentDate).format('YYYY-MM-DD');
            const teacher_ids = this.selectedTeacherIds.map(item => item.value);
            const student_ids = this.selectedStudentsIds.map(item => item.value);
            await Promise.all([
                this.fetchLessonsSchedule({
                    date,
                    teacher_ids,
                    student_ids,
                    gender: this.gender,
                    language: this.language,
                }),
                this.fetchAvailableDaysSchedule({
                    date,
                    teacher_ids,
                    gender: this.gender,
                    language: this.language,
                }),
            ]);
        },
        openItemDetails(item, isLesson = false) {
            if (!item.isLesson && !item.details && !isLesson) {
                this.$modal.show(
                    EditTimeModal,
                    {
                        create: !item,
                        record: item ? item : {},
                        actionHandler: this.handleSaveTimeAction,
                        removeHandler: this.handleRemoveTimeAction,
                        teacherId: item.teacher.id,
                    },
                    {
                        height: 'auto',
                        maxHeight: 900,
                        scrollable: true,
                    },
                );
            }

            if (!!item && item.isLesson) {
                this.$modal.show(
                    EditLessonModal,
                    {
                        record: item,
                        actionHandler: this.handleUpdateAction,
                        removeHandler: this.handleRemoveAction,
                        lessonId: item.id
                    },
                    {
                        height: 'auto',
                        maxHeight: 900,
                        scrollable: true,
                    },
                );
            }
            if (!!item && !item.isLesson && !!item.details && item.details.length > 0) {
                this.$modal.show(
                    TimeLineModal,
                    {
                        details: item.details,
                    },
                    {
                        height: 'auto',
                        maxHeight: 900,
                        scrollable: true,
                    },
                );
            }

        },
        async handleSaveTimeAction(data) {
            const result = await this.saveCalendarTime(data);
            if (result.status === 'success') {
                this.showNotify('Данные сохранены!');
                await this.loadShedule();
                return true;
            }
            this.showErrorNotify('Ошибка сохранения: ' + result.errors[0]);
            return false;
        },
        async handleRemoveTimeAction(data) {
            const result = await this.removeCalendarTime(data);
            this.showNotify('Временной интервал удален!');
            await this.loadShedule();
        },
        addLesson() {
            this.$modal.show(
                CreateLessonModal,
                {
                    beforeCreatedAction: this.handleCreateAction,
                },
                {
                    height: 'auto',
                    maxHeight: 900,
                    scrollable: true,
                },
            );
        },
        addFreeTime() {
            const teacherId = this.selectedTeacherIds.length === 1 ? this.selectedTeacherIds[0] : null;
            this.$modal.show(
                EditTimeManagerModal,
                {
                    teacherList: this.teachersOptionsList,
                    teacherId,
                    create: true,
                    actionHandler: async (teacherId, payload) => {
                        try {
                            const {data} = await this.$axios.post(`/api/teacher/${teacherId}/schedule/add`, {
                                ...payload,
                            });
                            if (data.success) {
                                this.$toasted.success('Время добавлено!', {position: 'bottom-right'});
                                this.$modal.hideAll();
                                await this.loadShedule();
                            }
                        } catch (e) {
                            this.$toasted.error('Ошибка: ' + e, {position: 'bottom-right'});
                        }
                    },
                },
                {
                    height: 'auto',
                },
            );
        },
        async handleUpdateAction(data) {
            // TODO Сделать обновление данных календаря
        },
        async handleCreateAction(data) {
            // TODO Сделать обновление данных календаря
        },
        async handleRemoveAction(data) {
            // TODO Сделать обновление данных календаря
        },
        showNotify(text) {
            this.$toasted.success(text, {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
        showErrorNotify(text) {
            this.$toasted.error(text, {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
        changeViewType(name = 'schedule') {
            this.viewType = name;
            return false;
        },
        currentViewType(name) {
            return name === this.viewType;
        },
    },
    watch: {
        getTeachersOptionsList: function (options) {
            if (options.length > 0) {
                this.teachersOptionsList = [{value: -1, text: 'Все преподаватели'}, ...options];
                // По умолчанию выбираем 1 учителя
                // const firstTeacher = options.find(item => (item.value != -1));
                // const firstTeacher = options.find(item => item);
                // if (!this.selectedTeacherIds.find(item => item.value - 1)) {
                //     this.selectedTeacherIds = [firstTeacher];
                // }
            } else {
                this.updateFilterByKey({key: 'teachers_ids', value: []});
                this.selectedTeacherIds = [];
                this.teachersOptionsList = [];
            }
            this.loadShedule();
        },
        '$store.state.lessons.periodicLessonCreateSuccessFlag'() {
            if (this.$store.state.lessons.periodicLessonCreateSuccessFlag) {
                this.loadShedule();
                this.$store.state.lessons.periodicLessonCreateSuccessFlag = false;
            }
        },
        '$store.state.lessons.periodicLessonRemoveSuccessFlag'() {
            if (this.$store.state.lessons.periodicLessonRemoveSuccessFlag) {
                this.loadShedule();
                this.$store.state.lessons.periodicLessonRemoveSuccessFlag = false;
            }
        },
        getScheduleRefresh() {
            if (this.getScheduleRefresh) {
                this.loadShedule();
                this.setScheduleRefresh()
            }
        },
    },
    async created() {
        await this.loadTeacherOptions();
        this.setFilterByKey({key: 'date_from', value: moment(this.currentDate).startOf('week').format('YYYY-MM-DD')});
        this.setFilterByKey({key: 'date_to', value: moment(this.currentDate).endOf('week').format('YYYY-MM-DD')});
    },
};
</script>

<style lang='scss'>
.gender__label {
    legend {
        border-bottom: none !important;
    }
}
</style>

<style scoped lang='scss'>
/*.my__control {*/
/*    display: inline-block;*/
/*    margin-right: 10px;*/
/*}*/

.h4-title {

}

.teacher-wrap {
    margin-bottom: 20px;

    span {
        font-size: 16px;
    }

    .teacher-selector {
        max-width: 300px;
    }
}

.schedule {
    position: relative;
    padding-left: 38px;
    /*min-width: 940px;*/
    /*overflow: auto;*/

    .hours-line {
        position: absolute;
        left: 0;
        top: 0;

        .hour {
            font-size: 12px;
            border-bottom: 1px solid gray;
        }

        .sep {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid gray;
        }
    }

    .days-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: auto;
        align-content: start;
    }

    .day-item {
        flex: 1 0 auto;
        margin: 4px;

        .day-title {
            text-align: center;
            height: 22px;
        }

        .hours-line {
            position: relative;
            height: 600px;
            background-color: #efefef;

            .free-time {
                position: absolute;
                z-index: 30;
                width: 100%;
                min-height: 25px;
                //background-color: #b0c5f5;
                background-color: rgba(176, 197, 245, 0.5);
                text-align: center;
                box-sizing: border-box;
                padding: 3px;
                transition: .1s;

                &:hover {
                    background: rgba(176, 197, 245, 0.8);
                }
            }

            .collected-free-time {
                background-color: rgb(189, 245, 176);
            }

            .lesson-time {
                position: absolute;
                z-index: 40;
                width: 100%;
                min-height: 25px;
                color: #fff;
                background-color: #326709;
                text-align: center;
                box-sizing: border-box;
                padding: 3px;
                border-bottom: 1px solid #fff;
                cursor: pointer;

                &.trial {
                    background-color: #e0d334;
                    color: #000;
                }

                .dot {
                    position: absolute;
                    bottom: 4px;
                    right: 4px;
                    height: 8px;
                    width: 8px;
                    border-radius: 8px;

                    &.finished {
                        background-color: #0EA804;
                    }

                    &.canceled {
                        background-color: #0b16a8;
                    }

                    &.skipped {
                        background-color: #a8241d;
                    }
                }
            }
        }
    }
}
</style>
