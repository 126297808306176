<template>
  <div class="weeks-picker">
    <div class="week-selector-wrapper">
<!--      <div class="col-2 text-left">-->
<!--&lt;!&ndash;        <button class="btn btn-sm btn-primary" @click="goBackward">Назад</button>&ndash;&gt;-->
<!--      </div>-->
      <div class="text-center week-title">
        <button class="btn btn-sm btn-primary" @click="goBackward"><i class="fa fa-arrow-left"></i> Назад</button>

        Неделя: {{firstDayOfWeek}} - {{lastDayOfWeek}}

        <button class="btn btn-sm btn-primary" @click="goForward">Вперед <i class="fa fa-arrow-right"></i></button>
      </div>
<!--      <div class="col-2 text-right">-->
<!--&lt;!&ndash;        <button class="btn btn-sm btn-primary" @click="goForward">Вперед</button>&ndash;&gt;-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'WeeksPicker',
    props: {
      onChangeDate: {
        type: Function
      }
    },
    data() {
      return {
        currentDate: new Date()
      };
    },
    computed: {
      firstDayOfWeek: function() {
        return moment(this.currentDate).startOf('isoWeek').day(1).format('DD MMM');
      },
      lastDayOfWeek: function() {
        return moment(this.currentDate).startOf('isoWeek').day(7).format('DD MMM');
      }
    },
    methods: {
      goForward() {
        this.currentDate = moment(this.currentDate).add(7, 'd').toDate();
        this.onChangeDate({
          date: this.currentDate,
          start: this.firstDayOfWeek,
          end: this.lastDayOfWeek
        });
      },
      goBackward() {
        this.currentDate = moment(this.currentDate).subtract(7, 'd').toDate();
        this.onChangeDate({
          date: this.currentDate,
          start: this.firstDayOfWeek,
          end: this.lastDayOfWeek
        });
      }
    },
    mounted() {
      //console.log('CALENDAR')
    }
  };
</script>

<style scoped lang="scss">
.week-selector-wrapper {
    justify-content: center;
}
.week-title {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
  }


</style>
