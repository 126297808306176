<template>
    <div>
        <h4>{{ message }}</h4>
    </div>
</template>

<script>
    import {authenticationService} from '@/core/services';
    import {mapMutations, mapActions} from 'vuex';

    export default {
        name: "AccessTokenPage",
        data() {
            return {
                message: null
            }
        },
        methods: {
            ...mapMutations({
                setRole: 'user/setRole',
                setName: 'user/setName',
                setAuth: 'user/setAuth',
                setUserId: 'user/setUserId',
                setConstants: 'common/setConstants',
            }),
            ...mapActions({fetchTeacherProfile: 'user/fetchTeacherProfile'}),
            async login() {
                this.errorMessage = null;
                const token = this.$route.params.token;

                if (token && token.length > 3) {
                    try {
                        this.message = 'Loading...';
                        const { data } = await authenticationService.validateToken(token);

                        if (data.status === 'success') {
                            this.setAuth(true);
                            this.setUserId(data.teacher_id);
                            this.setRole(data.user_role);
                            this.setName(data.user_name);
                            await this.fetchTeacherProfile(data.teacher_id);
                            this.$i18n.locale = this.$store.getters["user/getTeachingLang"];
                            await this.setConstants(data.constants);
                            await this.$router.push(data.route);
                        } else if (data.status === 'wrong_credentials') {
                            this.message = data.message
                        } else {
                            this.message = data.message;
                        }
                    } catch (e) {
                        this.message = "Invalid access link";
                        console.error("ERR", e)
                    }
                }
            },
        },
        created() {
            this.returnUrl = this.$route.query.returnUrl || '/';
            this.login()
        },
    }
</script>

<style lang="scss" scoped></style>