<template>
    <div class="calendar-wrapper">
          <div class="calendar-overflow">
              <div class="calendar-header row">
                  <div class="col-5 d-flex justify-content-start pl-0">
                      <WeeksPicker :onChangeDate='onChangeDatePeriod'/>
                  </div>
                  <div class="btn-wrap col-7 d-flex justify-content-end">
                      <button v-if="getLengthSelectedCells()" @click="clearSelectedCells()" class="btn btn-primary ml-2 clear-selected">Очистить выборку</button>
                      <button class='btn btn-primary ml-2 btn-add-free-items'
                              :class="{'btn-focus': getLengthSelectedCells() > 1}"
                              :disabled="!getLengthSelectedCells()"
                              @click="addFreeTime()"
                              v-if="isTeacher">
                          {{ $t("calendar.Добавить свободное время") }}
                      </button>
                      <button class='ml-2 btn btn-primary text-white btn-add-range' @click="createFreeRange">{{ $t('calendar.Добавить свободный период') }}
                      </button>
                      <button class='ml-2 btn btn-primary btn-add-lesson'
                              :class="{'btn-focus': getLengthSelectedCells() === 1}"
                              :disabled="getLengthSelectedCells() > 1"
                              @click="addLesson">
                          {{ $t('calendar.Добавить занятие') }}
                      </button>

                  </div>
              </div>

              <hr/>

              <div>
                  <div class='calendar'>
                      <div class='time-line'>
                          <div class='time-offset'></div>
                          <div class='time' v-for='time in timeLine'
                               :key='time.time'
                               :style='{height: getHeightInterval()}'>
                              {{ time.time }}
                          </div>
                      </div>

                      <div class='days-container'>
                          <div class='day-item' v-for='day in getDays' :key='day.date'>
                              <div class='day-title'>{{ day.date | toDayTitle }}</div>
                              <div class="times-container">
                                  <div class="time-item"
                                       v-for='timeItem in day.timeLine'
                                       :key="day.date + ' '+ timeItem.timeFrom"
                                       :style='{height: getHeightInterval()}'
                                       @click="clickCell(timeItem)"
                                       :class="{'selected-cell': isSelectedCell(timeItem.date, timeItem.timeFrom), 'selected-cell-many': getLengthSelectedCells() > 1}"
                                  >
                                      <div class="lesson"
                                           v-for='lesson in timeItem.lessons'
                                           :key="lesson.id"
                                           :style='{height: getHeightMinutes(lesson.duration), top: getOffsetTop(`${day.date} ${timeItem.timeFrom}`, lesson.date_from)}'
                                           :class="[lesson.status, lesson.lesson_type]"
                                           v-b-tooltip.hover
                                           :title="createTitleLabel(lesson)"
                                           :data-id="lesson.id"
                                           @click="openLesson(lesson)"
                                      >
                                          <div class="lesson-student">{{lesson.student_full_name}}</div>
                                          <div class="lesson-time">{{ lesson | toDayRange }}</div>
                                      </div>

                                      <div class="available-time"
                                           v-for='availableTime in timeItem.availableTime'
                                           :key="availableTime.id"
                                           :style='{height: getHeightMinutes(availableTime.duration), top: getOffsetTop(`${day.date} ${timeItem.timeFrom}`, availableTime.date_from)}'
                                           @click="openAvailableTimeModal(availableTime)"
                                      >

                                      </div>

                                      <i v-if="timeItem.lessons.length > 1"
                                         class='fa fa-th-list lesson-list-icon'
                                         aria-hidden='true'
                                         @click="showLessons(day.date, timeItem.timeFrom, timeItem.timeTo, timeItem.lessons)"
                                      ></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="lesson-legend col-12">
                      <LessonLegendComponent :show-free-time="true"/>
                  </div>
              </div>
          </div>
    </div>
</template>


<script>
import moment from 'moment';
import {mapGetters, mapActions, mapMutations} from 'vuex';
import CreateLessonModal from '@/components/Modal/CreateLessonModal';
import TimeLineModal from '@/components/Modal/TimeLineModal';
import LessonListModal from './LessonListModal';
import EditLessonModal from '@/components/Modal/EditLessonModal';
import WeeksPicker from '@/components/WeeksPicker/WeeksPicker';
import EditTimeModal from '@/components/Modal/EditTimeModal';
import EditTimeManagerModal from '@/components/Modal/EditTimeManagerModal';
import LessonLegendComponent from "@/components/LessonLegendComponent";
import FreeRangeModal from "@/components/Teacher/FreeRangeModal.vue";

export default {
    name: 'NewLessonsCalendar',
    components: {WeeksPicker, LessonLegendComponent},
    props: {
      isTeacher: {
          type: Boolean,
          default: false,
      }
    },
    data: function () {
        return {
            selectedCells: {},
            interval: 30, //интервал блоков
            heightMinute: 2, // высота 1 минуты в пикселях
            deleteFreeTimeMode: false, // для удаления свободного времени у учителя
        };
    },
    filters: {
        toDayTitle(value) {
            return moment(value).format('ddd DD MMM');
        },
        toDayRange(item) {
            const from = moment(item.date_from).format('HH:mm');
            const to = moment(item.date_to).format('HH:mm');
            return `${from} - ${to}`;
        },
    },
    computed: {
        ...mapGetters({
            getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
            getLessonsSchedule: 'lessons/getLessonsSchedule',
            getAvailableDaysShedule: 'lessons/getAvailableDaysShedule',
            getDayHoursRange: 'lessons/getDayHoursRange',
            getLessons: 'lessonsSearch/getLessons',
            getAvailableTime: 'lessonsSearch/getAvailableTime',
            getFilters: 'lessonsSearch/getFilters',
            getTeachersOptionsList: 'teacher/getTeachersOptionsList',
        }),
        getDays: function () {
            const days = [];
            const dateFrom = moment(this.getFilters.date_from);
            const dateTo = moment(this.getFilters.date_to);
            const startTime = this.hoursToMinutes(this.getDayHoursRange.dayStartHour);
            const endTime = this.hoursToMinutes(this.getDayHoursRange.dayEndHour);
            this.clearSelectedCells();

            const lessonsMap = this.groupByInterval(this.getLessons);
            const availableTimeMap = this.groupByInterval(this.getAvailableTime);

            if (startTime > endTime) return [];

            for (const date = moment(dateFrom); date.isSameOrBefore(dateTo); date.add(1, 'days')) {
                const timeLine = [];

                for (let minutes = startTime; minutes < endTime; minutes += this.interval) {
                    const timeFrom = this.minutesToTime(minutes);
                    const timeTo = this.minutesToTime(minutes + this.interval);

                    timeLine.push({
                        date: date.format('YYYY-MM-DD'),
                        timeFrom: timeFrom,
                        timeTo: timeTo,
                        lessons: lessonsMap[`${date.format("YYYY-MM-DD")} ${timeFrom}`] ?? [],
                        availableTime: this.getFilters.teachers_ids.length == 1 ? availableTimeMap[`${date.format("YYYY-MM-DD")} ${timeFrom}`] ?? [] : [],
                    });
                }

                days.push({
                    date: date.format('YYYY-MM-DD'),
                    timeLine: timeLine
                })
            }

            return days;
        },

        // построение временной шкалы на основе часа когда начинается рабочий день и когда заканчивается
        timeLine: function () {
            const startTime = this.hoursToMinutes(this.getDayHoursRange.dayStartHour);
            const endTime = this.hoursToMinutes(this.getDayHoursRange.dayEndHour);
            if (startTime > endTime) return [];

            const timeline = [];
            for (let minutes = startTime; minutes < endTime; minutes += this.interval) {
                timeline.push({
                    time: this.minutesToTime(minutes)
                });
            }
            return timeline;
        },
        isSelectedCell() {
            return ((date, timeFrom) => {
                return !!this.selectedCells[`${date} ${timeFrom}`];
            });
        },
    },
    methods: {
        ...mapActions({
            fetchLessonsSchedule: 'lessons/fetchLessonsSchedule',
            fetchAvailableDaysSchedule: 'lessons/fetchAvailableDaysSchedule',
            updateLessonDetails: 'lessons/updateLessonDetails',
            createLesson: 'lessons/createLesson',
            removeLesson: 'lessons/removeLesson',
            saveCalendarTime: 'lessons/saveCalendarTime',
            removeCalendarTime: 'lessons/removeCalendarTime',
            setScheduleRefresh: 'scheduler/scheduleRefresh',
            loadLessons: 'lessonsSearch/loadLessons',
            createFreeTime: 'scheduler/createFreeTime'
        }),
        ...mapMutations({
            setFilterByKey: 'lessonsSearch/setFilterByKey',
        }),
        groupByInterval(items) {
            const groups = {};

            for (const item of items) {
                const dateFrom = moment(item.date_from);
                const minutes = dateFrom.hours() * 60 + dateFrom.minutes();
                const key = `${dateFrom.format('YYYY-MM-DD')} ${this.minutesToTime(this.interval * Math.floor( minutes / this.interval))}`;

                if (!groups[key]) groups[key] = [];
                groups[key].push(item)
            }

            return groups;
        },
        clearSelectedCells() {
          this.selectedCells = {};
        },
        minutesToTime(totalMinutes) {
            let hours = Math.floor(totalMinutes / 60);
            if (hours >= 24) {
              hours = hours % 24
            }
            const minutes = totalMinutes % 60;

            return `${this.padToTwoDigits(hours)}:${this.padToTwoDigits(minutes)}`;
        },
        padToTwoDigits(num) {
            return num.toString().padStart(2, '0');
        },
        hoursToMinutes(hours) {
            return parseInt(hours) * 60;
        },
        getHeightInterval() {
            return `${this.heightMinute * this.interval}px`
        },
        getHeightMinutes(minute) {
            return `${minute * this.heightMinute}px`
        },
        getOffsetTop(dateFrom, lessonDate) {
            const from = moment(dateFrom);
            const to = moment(lessonDate);
            const diff = to.diff(from, 'minutes');
            return `${(diff * this.heightMinute)}px`;
        },
        showLessons(date, timeFrom, timeTo, lessons) {
            this.$modal.show(
                LessonListModal,
                {
                    date: date,
                    timeFrom: timeFrom,
                    timeTo: timeTo,
                    lessons: lessons,
                    openLessonHandler: this.openLesson
                },
                {
                    width: 850,
                    height: 'auto',
                    maxHeight: 900,
                    scrollable: true,
                },
            );
        },
        openLesson(lesson) {
            this.$modal.show(
                EditLessonModal,
                {
                    teacher: !!this.isTeacher,
                    record: lesson,
                    actionHandler: this.handleUpdateAction,
                    removeHandler: this.handleRemoveAction,
                    lessonId: lesson.id
                },
                {
                    height: 'auto',
                    maxHeight: 900,
                    scrollable: true,
                },
            );
        },
        openAvailableTimeModal(availableTime) {
            this.$modal.show(
                EditTimeModal,
                {
                    create: false,
                    record: availableTime,
                    actionHandler: this.handleSaveTimeAction,
                    removeHandler: this.handleRemoveTimeAction,
                    teacherId: availableTime.teacher_id,
                },
                {
                    height: 'auto',
                    maxHeight: 900,
                    scrollable: true,
                },
            );
        },
        createFreeRange() {
            this.$modal.show(FreeRangeModal, {
                teacher: !!this.isTeacher,
                teacherOptions: this.getTeachersOptionsList,
                actionHandler: this.handleUpdateAction,
            }, {
                name: 'LessonCalendarModal',
                width: '450px',
                height: 'auto',
                scrollable: false,
            });
        },
        addLesson() {
            this.$modal.show(
                CreateLessonModal,
                {
                    teacher: !!this.isTeacher,
                    lessonDatePrepared: Object.values(this.selectedCells)[0] ? Object.values(this.selectedCells)[0].date : null,
                    lessonTimePrepared: Object.values(this.selectedCells)[0] ? Object.values(this.selectedCells)[0].from : null,
                    beforeCreatedAction: this.handleCreateAction
                },
                {
                    height: 'auto',
                    maxHeight: 900,
                    scrollable: true,
                },
            );
        },
        createTitleLabel(item) {
            return 'Учитель: ' + item.teacher_full_name + ' Студент: ' + item.student_full_name + ', ' + item.status_title;
        },
        onChangeDatePeriod(event) {
            this.setFilterByKey({key: 'date_from', value: moment(event.date).startOf('week').format('YYYY-MM-DD')});
            this.setFilterByKey({key: 'date_to', value: moment(event.date).endOf('week').format('YYYY-MM-DD')});
            this.loadLessons();
        },
        showNotify(text) {
            this.$toasted.success(text, {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
        showErrorNotify(text) {
            this.$toasted.error(text, {
                position: 'bottom-right',
                action: {
                    text: 'Закрыть',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        },
        async handleSaveTimeAction(data) {
            const result = await this.saveCalendarTime(data);
            if (result.status === 'success') {
                this.showNotify('Данные сохранены!');
                await this.loadLessons();
                return true;
            }
            this.showErrorNotify('Ошибка сохранения: ' + result.errors[0]);
            return false;
        },
        async handleRemoveTimeAction(data) {
            const result = await this.removeCalendarTime(data);
            this.showNotify('Временной интервал удален!');
            await this.loadLessons();
        },
        async handleUpdateAction(data) {
            await this.loadLessons();
        },
        async handleCreateAction(data) {
            this.clearSelectedCells();
            await this.loadLessons();
        },
        async handleRemoveAction(data) {
            await this.loadLessons();
        },
        isEmptyTime(timeItem) {
            return !timeItem.lessons.length && !timeItem.availableTime.length;
        },
        isClickable(timeItem) {
            return this.isEmptyTime(timeItem) && this.selectedCells.length
        },
        clickCell(cell) {
            if (!cell.lessons.length && !cell.availableTime.length) {
                this.pushSelectedCell(cell);
            }
        },
        pushSelectedCell(cell) {
            const key = `${cell.date} ${cell.timeFrom}`;

            if (this.selectedCells[key]) {
                this.$delete(this.selectedCells, key)
            } else {

                if (!this.isTeacher && this.getLengthSelectedCells()) {
                    //Менеджер не может выбрать несколько клеток (только одну для добавления урока на это время)
                    this.selectedCells = {};
                }

                this.$set(this.selectedCells, key, {
                    date: moment(cell.date).format("DD.MM.YYYY"),
                    from: cell.timeFrom,
                    to: cell.timeTo,
                });
            }
        },
        getLengthSelectedCells() {
            return Object.keys(this.selectedCells).length;
        },
        async addFreeTime() {
            if (!this.$store.state.user.user_id || this.$store.state.user.role !== 'teacher') return;

            const res = await this.createFreeTime({ teacherId: this.$store.state.user.user_id, time: Object.values(this.selectedCells)});
            if (res.success) {
                this.showNotify(this.$t('toasted.Время добавлено'));
            } else {
                this.showErrorNotify(res);
            }

            this.clearSelectedCells();
            await this.loadLessons();
        }
    },
    watch: {},
    async created() {
        console.log(this.getFilters.teachers_ids.length)
    },
};
</script>

<style scoped lang='scss'>
//.calendar-wrapper {
//    overflow-y: auto;
//    background-color: #fff;
//}
//
//.calendar-overflow {
//    min-width: 1050px;
//}

.btn-add-free-items {
    &.btn-focus {
        //background-color: #f1a3ff;
        //border-color: #f1a3ff;
        //color: #000;
    }
}

.btn-add-lesson {
    &.btn-focus {
        //background-color: #43a3ff;
        //border-color: #43a3ff;
        //color: #000;
    }
}

.clear-selected {
    background-color: #ff5959;
    border-color: #ff5959;
}

.calendar-header {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 49;
    padding-left: 60px;
    padding-top: 20px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.calendar {
    display: flex;
    min-width: 940px;
    justify-content: space-between;
    flex-wrap: wrap;

    .time-line {
        width: 10%;
        width: calc(40px - 0px);
        text-align: center;
        .time-offset {
            height: 24px;
        }
        .time {
            font-size: 12px;
            margin: 4px;
        }
    }

    .days-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 90%;
        width: calc(100% - 40px);
        contain: paint;

        .day-title {
            text-align: center;
            height: 22px;
            position: sticky;
            top: 68px;
            z-index: 49;
            background-color: #fff;
        }

        .day-item {
            width: 14.2%;
            display: flex;
            flex-direction: column;

            &:nth-child(1) {
                .day-title {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 40px;
                        left: -40px;
                        top: 0;
                        height: 100%;
                        background-color: #fff;
                    }
                }
            }
        }

        .times-container {

        }

        .time-item {
            background-color: #efefef;
            margin: 4px;
            text-align: center;
            border-radius: 5px;
            position: relative;

            &.selected-cell {
                background-color: $selected_cell;

                &.selected-cell-many {
                    background-color: $selected_cell_many;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 6px;
                    bottom: 8px;
                    width: 12px;
                    height: 8px;
                    border: 3px solid #ff0000;
                    border-right: none;
                    border-top: none;
                    transform: rotate(-50deg);
                }
            }
        }
        
        .lesson {
            width: 100%;
            border-radius: 5px;
            border-top: 5px solid #0EA804;
            background-color: #cae5ff; //#dcedbb
            position: absolute;
            z-index: 5;
            padding: 0 5px;
            cursor: pointer;

            &.trial {
                border-top-color: $trial_lesson;
            }

            &.regular {
                border-top-color: $regular_lesson;
            }

            &.planned {
                background-color: $planned_lesson;
            }
            &.finished {
                background-color: $finish_lesson;
            }
            &.skipped {
                background-color: $skipped_lesson;
            }
            &.canceled {
                background-color: $canceled_lesson;
            }
        }

        .lesson-student {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 23px;
        }

        .lesson-list-icon {
            position: absolute;
            top: 0;
            right: 0;
            background-color: #fff;
            z-index: 10;
            padding: 5px;
            border-radius: 5px;
            color: #000;
            border: 1px solid #000;
            cursor: context-menu;
        }

        .available-time {
            width: 100%;
            border-radius: 5px;
            position: absolute;
            z-index: 4;
            background-color: #c4d3f3;
            cursor: pointer;
        }
    }
}

.lesson-legend {
    position: sticky;
    z-index: 49;
    bottom: 0;
    padding: 8px 8px;
    background-color: #fff;
}
</style>