<template>
    <p class="paginator-counter m-0">
      {{getPaginationCounterText}}
    </p>
</template>

<script>
export default {
  name: "PaginationCounter",
  props: {
    meta: {
      type: Object,
      required: true
    }
  },
  computed: {
    getPaginationCounterText() {

      let to = 0

      if (this.meta.to) {
        to = this.meta.to
      }

      if (this.meta.total < this.meta.per_page) {
        return `0-${to} из ${this.meta.total}`
      }

      if (this.meta.to % this.meta.per_page) {
        return `${to - (this.meta.to % this.meta.per_page)}-${this.meta.to} из ${this.meta.total}`
      }

      return `${to - this.meta.per_page}-${this.meta.to} из ${this.meta.total}`

    },
  }
}
</script>

<style scoped lang="scss">

</style>