<template>
    <div class='input-group'>
        <input type='search' class="form-control h-100" v-model='searchWord'
               id='search-input'>
        <span class='input-group-append'>
                        <button type='button' class='btn btn-default'>Поиск</button>
                    </span>
    </div>
</template>

<script>
import { debounce } from 'debounce';

export default {
    name: 'TableSearchBar.vue',
    data() {
        return {
            searchWord: null
        }
    },
    watch: {
        'searchWord': debounce(function(val) {
            this.$emit('onSearch', val)
        }, 500)
    }
};
</script>

<style scoped>

</style>