<template>
    <div class="teacher_card">
        <div class="py-4 container" :class="{ active: isCustomOptionSelected, card_blue: true }" @click="onChangeValue">
            <b-form-group
                label="Все преподаватели"
                label-for="input-teachers"
            >
                <b-form-select id="input-teachers" v-model="selectedTeacherId" :options="teachers" :state="validateTeacher"></b-form-select>
                <b-form-invalid-feedback>
                    Не выбран преподаватель
                </b-form-invalid-feedback>
            </b-form-group>
            Время:
            <NormalTimePicker v-model="pickedTime" id="original_lessontime" :state="dateValid"/>
        </div>
    </div>
</template>

<script>
    import NormalTimePicker from '../../components/NormalTimePicker';

    export default {
        name: 'AllTeachersCard',
        components: { NormalTimePicker },
        props: {
            onSelect: {
                type: Function,
                required: true
            },
            onChange: {
                type: Function,
                required: true
            },
            lessonTime: {
                type: String,
                required: false,
                default: null
            },
            currentOption: {
                type: String,
                required: false,
                default: null
            },
            teacherList: {
                type: Array,
                required: true,
            },
            dateFrom: {
                type: [String, Number],
                required: false,
                default: new Date().setHours(6, 0, 0)
            },
            dateEnd: {
                type: [String, Number],
                required: false,
                default: new Date().setHours(24, 0, 0)
            }
        },
        data() {
            return {
                pickedTime: '',
                selectedTeacherId: 0,
                teachers: [],
                time: "",
            }
        },
        watch: {
            teacherList: function () {
                this.fillTeachers();
            },
            selectedTeacherId: function () {
                this.onChangeValue();
            },
            pickedTime: function (newTime, oldTime) {
                this.onChangeValue();
            },
            lessonTime: function (newTime, oldTime) {
                this.pickedTime = this.lessonTime;
            }
        },
        created() {
            this.fillTeachers();
        },
        computed: {
            dateValid() {
                return !!this.pickedTime;
            },
            isCustomOptionSelected() {
                return (this.currentOption === 'custom');
            },
            validateTeacher() {
                if (this.isCustomOptionSelected) {
                    return (this.selectedTeacherId > 0);
                }
                return true;
            }
        },
        methods: {
            onChangeValue() {
                this.onSelect('custom');
                this.onChange({
                    id: this.selectedTeacherId,
                    time: this.pickedTime.substring(0, 5)
                });
            },
            fillTeachers() {
                this.teachers = [
                    { value: 0, text: '- Выберите преподавателя -' },
                    ...(this.teacherList.map(teacher => {
                        return { value: teacher.id, text: teacher.full_name };
                    }))
                ];
            }
        }
    }
</script>

<style lang="scss" scoped>
    .teacher_card {
        //padding: 0px 20px;
        display: flex;

        .container {
            border-radius: 10px;
            padding: 0.375rem 2rem 0.375rem 1rem;
            box-shadow: 4px 0px 25px rgba(#000, 0.1);
        }

        .dropdown-menu.show {
            height: 200px !important;
            overflow-y: auto !important;
        }

        .container {
            position: relative;
            border: 1px solid rgba(#000, 0);
            border-radius: 10px;

            &.active {
                border: 1px solid rgba(#1b8e72, 0.5);

                &::after, &::before {
                    opacity: 1;
                    background: #1b8e72;
                }
            }

            &::after {
                content: "";
                z-index: 10;
                position: absolute;
                right: 20px;
                top: 20px;
                display: block;
                width: 6px;
                height: 2px;
                background: #aaa;
                transform: rotate(45deg);
                opacity: 0;
            }

            &::before {
                content: "";
                z-index: 10;
                position: absolute;
                right: 20px;
                top: 20px;
                display: block;
                width: 15px;
                height: 2px;
                background: #aaa;
                transform: rotate(135deg) translateY(-5px) translateX(-12px);
                opacity: 0;
            }

            transition: .5s;

            &:hover {

                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    .card_blue {
        background-color: #b5daf9;
    }
</style>