<template>
    <div style='font-size: 16px'><i v-for='type in notificaationIcons' :title='type' :key='type' :class='classMap[type]' class='p-1'></i></div>
</template>

<script>
export default {
    props: ['notificationWay'],
    name: 'LeadNotifications',
    data() {
        return {
            notificaationIcons: [],
            classMap: {
                telegram: "fa fa-telegram tg",
                whatsapp: "fa fa-whatsapp whatsapp",
                email: "fa fa-envelope-o email"
            },
        };
    },
    created() {
        if (this.notificationWay) {
            this.notificaationIcons = this.notificationWay.split(',');
        }
    },
};
</script>

<style lang='scss' scoped>
.whatsapp {
    color: #128c7e;
}
.tg {
    color: #0088cc;
}
.email {
    color: #dd4b39;
}
</style>