import { render, staticRenderFns } from "./LeadsTable.vue?vue&type=template&id=4f77edc6&scoped=true"
import script from "./LeadsTable.vue?vue&type=script&lang=js"
export * from "./LeadsTable.vue?vue&type=script&lang=js"
import style0 from "./LeadsTable.vue?vue&type=style&index=0&id=4f77edc6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f77edc6",
  null
  
)

export default component.exports