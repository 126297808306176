<template>
  <div>
    <Preloader v-if='isLoading'/>

    <h4 class='page-title'>Новая Заявка</h4>
    <div class='widget'>
      <b-form class="row g-1" @submit.prevent="createLeadAndAccept">
        <b-form-group
            label="ФИО ученика: *"
            class="col-6"
            label-for="fio"
            min="2"
        >
          <b-form-input
              id="fio"
              type="text"
              required
              placeholder="Имя ученика"
              autocomplete="off"
              :state="nameValidation"
              v-model="form.student_full_name"
          />

          <b-form-invalid-feedback>
            Имя не может состоять менее чем из 2-х букв
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Возраст ученика: *"
            min="2"
            class="col-2"
            label-for="age"
        >
          <b-form-input
              id="age"
              type="number"
              placeholder="Возраст ученика"
              required
              autocomplete="off"
              :state="ageValidation"
              v-model="form.student_age"
          />

          <b-form-invalid-feedback>
            Возраст не может быть ниже 1
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Возрастная группа ученика: *"
            min="2"
            class="col-2"
            label-for="age-group"
        >
        <b-form-select
            id="age-group"
            required
            :state="ageGroupsValidation"
            v-model="form.age_group"
        >
            <b-form-select-option v-for='(value) in getAgeGroups' :key='value.value' :value='value.value'>{{value.text}}</b-form-select-option>
        </b-form-select>

          <b-form-invalid-feedback>
            Выберете возрастную группу
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Регион обслуживания:"
            class="col-2"
            label-for="location"
        >
          <b-form-select
              id="location"
              required
              :options="getLocationsList"
              :state="locationValidation"
              v-model="form.location"
          />

          <b-form-invalid-feedback>
            Выберите регион
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Уровень подготовки: *"
            class="col-6"
            label-for="exp"
        >
          <b-form-select
              id="exp"
              :state="experienceValidation"
              :options="getExperienceList"
              :value="form.experience"
              required
              v-model="form.experience"
          />

          <b-form-invalid-feedback>
            Выберите уровень подготовки
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="ФИО родителя (попечителя): *"
            class="col-6"
            label-for="pname"
        >
          <b-form-input
              id="pname"
              type="text"
              placeholder="ФИО родителя"
              required
              autocomplete="off"
              :state="parentNameValidation"
              v-model="form.parent_name"
          />

          <b-form-invalid-feedback>
            ФИО родителя должно состоять хотя бы из 2 букв
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Email родителя (попечителя): *"
            class="col-6"
            label-for="pemail"
        >
          <b-form-input
              id="pemail"
              type="email"
              placeholder="Email родителя"
              required
              autocomplete="off"
              :state="validateEmail"
              v-model="form.parent_email"
          />

          <b-form-invalid-feedback>
            Некорректный email
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Телефон: *"
            class="col-6"
            label-for="phone"
        >
          <b-form-input
              id="phone"
              type="text"
              placeholder="Телефон"
              required
              autocomplete="off"
              :state="phoneNumberValidator"
              v-mask="'#####################'"
              v-model="form.parent_phone"
          />

          <b-form-invalid-feedback>
            Заполните телефон (минимум 6 символов)
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Часовой пояс: *"
            class="col-3"
            label-for="utc"
        >
          <b-form-select
              id="utc"
              :options="getAllTimezones"
              :value="form.timezone"
              :state="timezoneValidator"
              v-model="form.timezone"
              @change="convertUserLocalTimeToMoscowTime"
          />
        </b-form-group>

        <b-form-group
            label="Местное время занятия: *"
            class="col-3"
            label-for="original_lessontime"
        >
          <NormalTimePicker
              id="original_lessontime"
              :state="timeValidation"
              v-model="originalLessonTime"
          />

          <b-form-invalid-feedback>
            Время занятия не может быть пустым
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Страна:"
            class="col-3"
            label-for="utc"
        >
          <b-form-select
              id="utc"
              :options="getCountriesCodes"
              v-model="form.country_code"
              @change="convertUserLocalTimeToMoscowTime"
          />

          <b-form-invalid-feedback>
            Должна быть выбрана страна
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Способ связи: *"
            class="col-3"
            label-for="notification"
            description="например: whatsapp, telegram, звонки, sms"
        >
          <multiselect
              id="notification"
              :taggable="true"
              :close-on-select="false"
              :multiple="true"
              :options="getNotificationWay"
              v-model="form.notifications_way"
          />

          <b-form-invalid-feedback>
            Должен быть выбран хотя бы один способ
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Дата занятия:"
            class="col-3"
            label-for="lessondate"
        >
          <b-form-datepicker
              id="lessondate"
              locale="ru"
              :state="dateValid"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :min="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"
              v-model="LessonDate"
          />

          <b-form-invalid-feedback>
            Дата занятия не может быть пустой
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="Время занятия: *"
            class="col-3"
            label-for="lessontime"
        >
          <NormalTimePicker id="lessontime" :state="timeValidation" v-model="LessonTime"/>

          <b-form-invalid-feedback>
            Время занятия не может быть пустым
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            label="UTM"
            class="col-6"
            label-for="utm_data"
        >
          <textarea class="form-control" id="utm_data" disabled v-model="form.utm"></textarea>
        </b-form-group>

        <b-form-group
            label="Комментарий"
            class="col-6"
            label-for="utm_data"
        >
          <textarea class="form-control" id="note" v-model="form.note"></textarea>
        </b-form-group>

        <h5 class="col-12 d-flex align-items-center mb-4">
          <span>График преподавателей на указанную дату *</span>
          <i
              v-show="isLoadingSuggestions"
              class="la la-spinner la-spin ml-2" :style="{fontSize: '30px'}"
          ></i>
        </h5>

        <div
             v-if="suggestions && Object.keys(suggestions).length"
             class="col-12"
        >
          <div class="row">
            <template v-if="suggestions.best_suggestions && suggestions.best_suggestions.length">
              <b-form-group
                  class="col-6 col-lg-4"
                  v-for="suggestion in suggestions.best_suggestions"
                  :key="suggestion.id"
              >
                <TeacherCard
                    :teacherName="suggestion.teacher.full_name"
                    :dateFrom="suggestion.date_from"
                    :dateEnd="suggestion.date_to"
                    :id="suggestion.teacher.id"
                    :lessonTime="LessonTime"
                    :thebest="true"
                    class="card_hover"
                    :currentOption="selectedTeacherOption"
                    :onSelect="selectTeacherOptionHandle"
                    :onChange="selectTeacherHandle"
                />
              </b-form-group>
            </template>

            <template v-else-if="suggestions.other_suggestions && suggestions.other_suggestions.length">
              <b-form-group
                  class="col-6 col-lg-4"
                  v-for="suggestion in suggestions.other_suggestions"
                  :key="suggestion.id"
              >
                <TeacherCard
                    :teacherName="suggestion.teacher.full_name"
                    :dateFrom="suggestion.date_from"
                    :dateEnd="suggestion.date_to"
                    :id="suggestion.teacher.id"
                    :lessonTime="LessonTime"
                    :thebest="false"
                    class="card_hover"
                    :currentOption="selectedTeacherOption"
                    :onSelect="selectTeacherOptionHandle"
                    :onChange="selectTeacherHandle"
                />
              </b-form-group>
            </template>

            <template v-else-if="suggestions.all_teachers && suggestions.all_teachers.length">
              <b-form-group class="col-6 col-lg-4">
                <AllTeachersCard
                    :teacherList="suggestions.all_teachers"
                    :currentOption="selectedTeacherOption"
                    :lessonTime="LessonTime"
                    :onSelect="selectTeacherOptionHandle"
                    :onChange="selectTeacherHandle"
                />
              </b-form-group>
            </template>
          </div>
        </div>

        <div v-else class='col-3'>
          <p class='badge badge-warning d-block'>
            Сначала выберите дату и время занятия
          </p>
        </div>

        <b-form-group class="col-12 text-right">
          <b-button
              type="button"
              class="mr-2"
              variant="outline-success"
              @click="createLead"
          >
            <b-spinner v-if="isCreating" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
            <span :class="{ captionPadding: isCreating }">Создать</span>
          </b-button>

          <b-button
              type="submit"
              class="mr-2"
              variant="success"
          >
            <b-spinner v-if="isAccepting" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
            <span :class="{ captionPadding: isAccepting }">Создать и Записать на пробное</span>
          </b-button>

          <b-button type="button" @click.prevent="$router.back()" variant="primary">Назад</b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {debounce} from 'debounce';
import {mapActions, mapGetters} from 'vuex';
import Multiselect from 'vue-multiselect';
import NormalTimePicker from '@/components/NormalTimePicker';
import TeacherCard from "@/components/Leads/TeacherCard";
import AllTeachersCard from "@/components/Leads/AllTeachersCard";
import LeadsTable from '@/components/Leads/LeadsTable';
import Preloader from '@/components/Preloader';
import notificationMixin from '@/mixins/notificationMixin';

const MOSCOW_TIMEZONE = 3;

export default {
  name: 'LeadsNew',
  mixins: [notificationMixin],
  components: {Preloader, LeadsTable, TeacherCard, AllTeachersCard, Multiselect, NormalTimePicker},
  data() {
    return {
      testtime: '',
      isLoading: false,
      isLoadingSuggestions: false,
      suggestions: null,
      form: {
        student_full_name: null,
        student_age: null,
        age_group: null,
        experience: null,
        parent_name: null,
        parent_email: null,
        parent_phone: null,
        notifications_way: [],
        lesson_datetime: null,
        original_lesson_time: null,
        timezone: null,
        note: null,
        utm: null,
        country_code: null,
        location: null,
      },
      originalLessonTime: '',
      LessonDate: '', // отдельно дата урока из lesson_datetime
      LessonTime: '', // отдельно время урока из lesson_datetime
      lessonDuration: 25,

      selectedTeacherOption: null,
      selectedTeacherId: null,
      selectedLessonTime: null,

      isCreating: false,
      isAccepting: false,
      isSaving: false,
    };
  },
  filters: {
    toDate(value) {
      return moment(value).format('DD.MM.YYYY HH:mm');
    },
    fromUnix(value) {
      return moment.unix(value);
    },
    toUnix(value) {
      return moment(value).unix();
    }
  },
  computed: {
    ...mapGetters({
      getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
      getAllTimezones: 'common/getAllTimezones',
      getExperienceList: 'common/getExperienceList',
      getNotificationWay: 'common/getNotificationWay',
      getCountriesCodes: 'common/getCountriesCodes',
      getLocationsList: 'settings/getLocationsList',
        getAgeGroups: 'common/getAgeGroups',
    }),
    nameValidation() {
      return (!!this.form.student_full_name && this.form.student_full_name.length >= 2);
    },
    ageValidation() {
      return (!!this.form.student_age && this.form.student_age >= 1);
    },
    experienceValidation() {
      return (this.form.experience !== null);
    },
    parentNameValidation() {
      return (!!this.form.parent_name && this.form.parent_name.length >= 2);
    },
    expierenceValidation() {
      return (this.form.experience >= 0);
    },
    phoneNumberValidator() {
      return (!!this.form.parent_phone && this.form.parent_phone.length >= 6);
    },
    timezoneValidator() {
      return (!!this.form.timezone);
    },
    validateEmail() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regex.test(this.form.parent_email ? this.form.parent_email.toLowerCase() : false);
    },
    dateValid() {
      return !!this.LessonDate;
    },
    timeValidation() {
      return !!this.LessonTime;
    },
    notificationValidator() {
      return (this.form.notifications_way.length > 0);
    },
    teacherSelected() {
      return (!!this.selectedTeacherId && !!this.selectedLessonTime);
    },
    locationValidation() {
      return (!!this.form.location);
    },
    ageGroupsValidation() {
      return (this.form.age_group !== null);
    },
    isFormValid() {
      const isValid = this.nameValidation
          & this.ageValidation
          & this.parentNameValidation
          & this.expierenceValidation
          & this.phoneNumberValidator
          & this.notificationValidator
          & this.teacherSelected
          & this.timezoneValidator
          & this.locationValidation;
      console.log(this.nameValidation
          , this.ageValidation
          , this.parentNameValidation
          , this.expierenceValidation
          , this.phoneNumberValidator
          , this.notificationValidator
          , this.timezoneValidator
          , this.teacherSelected
          , this.locationValidation
      );
      return isValid;
    },
    isFormValidForCreate() {
      const isValid = this.nameValidation
          & this.ageValidation
          & this.parentNameValidation
          & this.expierenceValidation
          & this.phoneNumberValidator
          & this.notificationValidator
          & this.timezoneValidator
          & this.locationValidation;
      console.log(
          this.nameValidation,
          this.ageValidation,
          this.parentNameValidation,
          this.expierenceValidation,
          this.phoneNumberValidator,
          this.notificationValidator,
          this.timezoneValidator,
          this.locationValidation
      );
      return isValid;
    }
  },
  watch: {
    // при изменении даты и времени обновляем список вариантов
    LessonDate: debounce(async function (val) {
      if (this.isLoading || this.isLoadingSuggestions) return;
      const date = moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm:ss');
      this.isLoadingSuggestions = true;
      await this.fetchSuggestions(date);
      this.isLoadingSuggestions = false;
    }, 300),
    LessonTime: debounce(async function (val) {
      if (this.isLoading || this.isLoadingSuggestions) return;
      // конвертируем в местное время
      this.convertUserLocalTimeToMoscowTime();
      // подгружаем варианты
      const date = moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm:ss');
      this.isLoadingSuggestions = true;
      await this.fetchSuggestions(date);
      this.isLoadingSuggestions = false;
    }, 300),
  },
  methods: {
    ...mapActions({
      createNewLeadAndAccept: 'leads/createNewLeadAndAccept',
    }),
    selectTeacherOptionHandle(type) {
      this.selectedTeacherOption = type; // какого типа блок выбран (custom или suggestion_ID)
    },
    async createLead() {
      if (!this.isFormValidForCreate) {
        alert('Заполните все обязательные поля');
        return;
      }

      try {
        this.isCreating = true;
        const { data } = await this.$axios.post('api/leads/create', this.getFormDataForCreate());
        this.isCreating = false;
        this.responseNotify(data, 'Заявка создана', async () => {
          await this.$router.push({name: 'LeadsDetail', params: {detail: data.data.id}});
        })
      } catch (e) {
        this.isCreating = false;
        this.$toasted.error(`Не удалось сохранить! ${e}`, {
          position: 'bottom-right',
          action: {
            text: 'Закрыть',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        })
      }
    },
    async createLeadAndAccept() {
      if (!this.isFormValid) {
        alert('Заполните все обязательные поля');
        return;
      }

      try {
        this.isAccepting = true;
        const result = await this.createNewLeadAndAccept(this.getFormData());

        if (result.status === 'success') {
          this.isAccepting = false;
          this.$toasted.success('Пробный урок создан!', {
            position: 'bottom-right',
            action: {
              text: 'Закрыть',
              onClick: (e, toastObject) => {
                toastObject.goAway(0);
              }
            }
          });
          await this.$router.push({name: 'LeadsPage'});
        } else {
          throw Error(Object.values(result.errors).join("\n"));
        }
      } catch (e) {
        this.isAccepting = false;
        this.$toasted.error(`Не удалось сохранить! ${e}`, {
          position: 'bottom-right',
          action: {
            text: 'Закрыть',
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            }
          }
        })
      }
    },
    selectTeacherHandle(payload) {
      this.selectedTeacherId = payload.id;
      this.selectedLessonTime = payload.time;
    },
    convertUserLocalTimeToMoscowTime() {
      const dateTime = `${this.LessonDate} ${this.LessonTime}`;

      if (this.form.timezone) {
        let timezoneOffset = this.form.timezone.replace('UTC', '').replace('utc', '');
        if (timezoneOffset) {
          timezoneOffset = parseInt(timezoneOffset);
          if (timezoneOffset !== MOSCOW_TIMEZONE) {
            const offset = timezoneOffset - MOSCOW_TIMEZONE;
            this.originalLessonTime = moment(dateTime).add(offset, 'h').format('HH:mm');
            return;
          }
        }
        this.originalLessonTime = moment(dateTime).format('HH:mm');
      }
    },
    getFormData() {
      return {
        ...this.form,
        lesson_datetime: this.LessonDate && this.LessonTime ? moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm:ss') : null,
        lesson_duration: this.lessonDuration,
        notifications_way: this.form.notifications_way.join(','),
        original_lesson_time: this.LessonDate && this.originalLessonTime ? moment(`${this.LessonDate} ${this.originalLessonTime}`).format('HH:mm') : null,
        selected_lesson_datetime: this.LessonDate && this.selectedLessonTime ? moment(`${this.LessonDate} ${this.selectedLessonTime}`).format('YYYY-MM-DD HH:mm:ss') : null,
        teacher_id: this.selectedTeacherId,
      }
    },
    getFormDataForCreate() {
      return {
        ...this.form,
        lesson_datetime: this.LessonDate && this.LessonTime ? moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm') : null,
      }
    },
    async fetchSuggestions(date) {
      try {
        const { data: suggestions } = await this.$axios.get('/api/calendar/suggestions', {
          params: {
            date: date ?? '',
          }
        });

        this.suggestions = suggestions;
      } catch (ex) {
        console.log('cant fetch suggestions: ', ex);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
label.form-control {
  display: none;
}

.card_hover {
  position: relative;

  &::after {
    z-index: 10;
    position: absolute;
    right: 20px;
    top: 20px;
    display: block;
    width: 20px;
    height: 20px;
    background: red;
  }
}

.lesson-card {
  border-radius: 10px;
  background-color: #8cd4b4;
  font-size: 16px;
}

.info_red {
  color: #FD5F00;
}

.captionPadding {
  margin-left: 10px;
}
</style>