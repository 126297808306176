<template>
    <div class='temp-leads'>
        <Preloader v-if='loader' />

        <div class='filter__container'>
            <div class='row mb-3 justify-content-between'>
                <div class='input-group col-12'>
                    <input
                        type='search'
                        class='form-control h20'
                        autocomplete='off'
                        :value='search'
                        @input='debounceSearch'
                    >
                </div>
            </div>
        </div>

        <div class='widget'>
            <table class='table table-hover'>
                <thead class='table_head'>
                <tr>
                    <th>№</th>
                    <th>Заявка от</th>
                    <th>Имя</th>
                    <th>Родитель</th>
                    <th>Номер родителя</th>
                    <th class='text-center'>Увед</th>
                    <th class='text-center'>Занятие</th>
                    <th class='text-center'>Форма</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='lead in leads' :key='lead.id'>
                    <td>
                        <span>{{ lead.id ? lead.id : '---' }}</span>
                    </td>
                    <td>
                        <span v-if='lead.created_at'>{{ lead.created_at | normalizeDate }}</span>
                        <span v-else>---</span>
                    </td>
                    <td>
                        <span class='d-block'>{{ lead.student_full_name ? lead.student_full_name : '---' }}</span>
                        <span class='d-block'>Возраст: {{ lead.student_age ? lead.student_age : '---' }}</span>
                    </td>
                    <td>{{ lead.parent_name ? lead.parent_name : '---' }}</td>
                    <td>
                        <span class='d-block'>
                            {{ lead.parent_phone_filtered ? lead.parent_phone_filtered : '---' }}
                        </span>
                        <span class='d-block'>
                            <template v-if='lead.country_code'>{{ normalizeCountryCode(lead.country_code) }}</template>
                            <template v-else>---</template>
                        </span>
                    </td>
                    <td class='text-center'>
                        <LeadNotifications v-if='lead.notifications_way' :notification-way='lead.notifications_way' />
                        <span v-else>---</span>
                    </td>
                    <td class='text-center'>
                        <span v-if='lead.lesson_datetime'>{{ lead.lesson_datetime | normalizeDate }}</span>
                        <span v-else>---</span>
                    </td>
                    <td class='text-center'>{{ lead.formName ? lead.formName : '---' }}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div v-if='pagination && pagination.current_page <= pagination.last_page' class='overflow-auto'>
            <b-pagination
                class='paginator'
                :total-rows='pagination.total'
                :per-page='pagination.per_page'
                @input='handlePageSelect'
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce } from 'debounce';
import { prepareForm } from '@/utils';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';
import Preloader from '@/components/Preloader';
import LeadNotifications from '@/components/Leads/LeadNotifications';

export default {
    name: 'TempLeads',
    components: { LeadNotifications, Preloader },
    data() {
        return {
            loader: false,
            search: null,
            leads: null,
            pagination: null,
        };
    },
    filters: {
        normalizeDate(date) {
            return dayjs(date).format('DD.MM.YYYY HH:mm');
        },
    },
    async created() {
        dayjs.extend(duration);
        dayjs.locale(ru);

        if (this.$route.query.query) this.search = this.$route.query.query;
        await this.fetchData(this.$route.query);
    },
    computed: {
        ...mapGetters({
            countriesMap: 'common/getCountriesCodesMap',
        }),
    },
    methods: {
        async fetchData(query = null) {
            try {
                this.loader = true;
                const prepared = {};

                // form data to object
                if (query && Object.keys(query).length > 0) {
                    const fd = prepareForm(query);
                    for (const [key, value] of fd) {
                        prepared[key] = value;
                    }
                }

                const { data } = await this.$axios.get('crm/uncompleted-leads', { params: { ...prepared } });
                this.leads = data.data;
                this.pagination = data.meta;
                this.loader = false;
            } catch (ex) {
                this.loader = false;
                console.error('cant fetch data: ' + ex);
            }
        },
        normalizeCountryCode(code) {
            if (this.countriesMap[code]) return this.countriesMap[code];
            return false;
        },
        debounceSearch: debounce(async function(e) {
            let val = e.target.value;
            const query = Object.assign({}, this.$route.query, { query: val });

            this.search = val;
            await this.$router.push({ query });
            await this.fetchData(this.$route.query);
        }, 1000),
        async handlePageSelect(val) {
            if (val) {
                const query = Object.assign({}, this.$route.query, { page: val });
                await this.$router.replace({ query });
                await this.fetchData(this.$route.query);
            }

            return false;
        },
    },
};
</script>

<style lang='scss' scoped></style>