<template>
    <div>
        <b-tabs>
            <b-tab title='Заявка'>
                <span v-if='isCopy' class='badge badge-copy'>копия</span>
                <h4 v-if="form.id" class="mb-4 detail__title">
                    ЗАЯВКА № {{ form.id }} от {{ form.created_at | toDate }}
                    <b-badge v-if='badge' class="ml-2" :variant="badge.css" pill>{{ badge.title }}</b-badge>
                </h4>
                <h4 v-else class="mb-4">Новая заявка</h4>
                <div class="leads-details widget">
                    <b-form class="row g-1" @submit.prevent="acceptLead">
                        <b-form-group
                            id="input-group-1"
                            label="ФИО ученика:"
                            class="col-6"
                            label-for="fio"
                        >
                            <b-form-input
                                id="fio"
                                v-model="form.student_full_name"
                                type="text"
                                placeholder="Имя ученика"
                                :disabled="isDisabled"
                                :state="nameValidation"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                Имя не может состоять менее чем из 2-х букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Возраст ученика:"
                            min="2"
                            class="col-2"
                            label-for="age"
                        >
                            <b-form-input
                                id="age"
                                v-model="form.student_age"
                                type="number"
                                placeholder="Возраст ученика"
                                :state="ageValidation"
                                :disabled="isDisabled"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                Возраст не может быть ниже 1
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            label="Возрастная группа ученика: *"
                            min="2"
                            class="col-2"
                            label-for="age-group"
                        >
                            <b-form-select
                                id="age-group"
                                required
                                :state="ageGroupsValidation"
                                v-model="form.age_group"
                            >
                                <b-form-select-option v-for='(value) in getAgeGroups' :key='value.value' :value='value.value'>{{value.text}}</b-form-select-option>
                            </b-form-select>

                            <b-form-invalid-feedback>
                                Выберете возрастную группу
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            label="Регион обслуживания:"
                            class="col-2"
                            label-for="location"
                        >
                          <b-form-select
                              id="location"
                              class=""
                              :options="getLocationsList"
                              :value="form.location"
                              :disabled="isDisabled"
                              v-model="form.location"
                          ></b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Уровень подготовки:"
                            class="col-6"
                            label-for="exp"
                        >
                            <b-form-select
                                id="exp"
                                class=""
                                :state="experienceValidation"
                                :options="getExperienceList"
                                :value="form.experience"
                                :disabled="isDisabled"
                                v-model="form.experience"
                            ></b-form-select>
                        </b-form-group>

<!--                        <b-form-group-->
<!--                            id="input-group-1"-->
<!--                            label="Примечание:"-->
<!--                            class="col-6"-->
<!--                            label-for="note"-->
<!--                        >-->
<!--                            <b-form-input-->
<!--                                id="note"-->
<!--                                v-model="form.note"-->
<!--                                type="text"-->
<!--                                placeholder="Примечание"-->
<!--                                :disabled="isDisabled"-->
<!--                            ></b-form-input>-->
<!--                        </b-form-group>-->

                        <b-form-group
                            id="input-group-1"
                            label="ФИО родителя (попечителя):"
                            class="col-6"
                            label-for="pname"
                        >
                            <b-form-input
                                id="pname"
                                v-model="form.parent_name"
                                type="text"
                                placeholder="ФИО родителя"
                                :disabled="isDisabled"
                                :state="parentNameValidation"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                ФИО родителя должно состоять хотябы из 2 букв
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Email родителя (попечителя):"
                            class="col-6"
                            label-for="pemail"
                        >
                            <b-form-input
                                id="pemail"
                                v-model="form.parent_email"
                                type="text"
                                placeholder="Email родителя"
                                :disabled="isDisabled"
                                :state="validateEmail"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                Некорректный email
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Телефон:"
                            class="col-6"
                            label-for="phone"
                        >
                            <b-form-input
                                id="phone"
                                v-model="form.parent_phone"
                                type="text"
                                v-mask="'#####################'"
                                placeholder="Телефон"
                                :disabled="isDisabled"
                                :state="phoneNumberValidator"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="Часовой пояс:"
                            class="col-3"
                            label-for="utc"
                        >
                            <b-form-select
                                id="utc"
                                class=""
                                :options="getAllTimezones"
                                :value="form.timezone"
                                :disabled="isDisabled"
                                @change="convertUserLocalTimeToMoscowTime"
                                v-model="form.timezone"
                                :state="timezoneValidator"
                            ></b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Местное время занятия:"
                            class="col-3"
                            label-for="original_lessontime"
                        >
                            <NormalTimePicker v-model="originalLessonTime" id="original_lessontime" :disabled="isDisabled" :state="timeValidation"/>
                            <b-form-invalid-feedback>
                                Время занятия не может быть пустым
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Страна проживания:"
                            class="col-3"
                            label-for="notification"
                            description=""
                        >
                            <b-form-select
                                id="utc"
                                class=""
                                :options="getCountriesCodes"
                                :value="form.country_code"
                                :disabled="isDisabled"
                                @change="convertUserLocalTimeToMoscowTime"
                                v-model="form.country_code"
                            ></b-form-select>

                            <b-form-invalid-feedback>
                                Должен быть выбран хотя бы один способ
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Способ связи:"
                            class="col-3"
                            label-for="notification"
                            description="например: whatsapp, telegram, звонки, sms"
                        >
                            <multiselect id="notification"
                                         v-model="form.notifications_way"
                                         :taggable="true"
                                         :state="notificationValidator"
                                         :close-on-select="false"
                                         :multiple="true"
                                         :options="getNotificationWay"
                                         :disabled="isDisabled"
                                         :value="form.notifications_way">
                            </multiselect>

                            <b-form-invalid-feedback>
                                Должен быть выбран хотя бы один способ
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Дата занятия:"
                            class="col-3"
                            label-for="lessondate"
                        >
                            <b-form-datepicker id="lessondate"
                                               v-model="LessonDate"
                                               :value="LessonDate"
                                               :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                               locale="ru"
                                               :disabled="false"
                                               :state="dateValid"
                                               >
                            </b-form-datepicker>
                            <b-form-invalid-feedback>
                                Дата занятия не может быть пустой
                            </b-form-invalid-feedback>
                        </b-form-group>

                        <b-form-group
                            label="Время занятия:"
                            class="col-3"
                            label-for="lessontime"
                        >
                            <NormalTimePicker v-model="LessonTime" id="lessontime" :disabled="false" :state="timeValidation"/>
                            <b-form-invalid-feedback>
                                Время занятия не может быть пустым
                            </b-form-invalid-feedback>
                        </b-form-group>


                            <b-form-group
                                label="UTM"
                                class="col-6"
                                label-for="utm_data"
                            >
                                <textarea class="form-control" disabled="disabled" id="utm_data" v-model="form.utm"></textarea>
                            </b-form-group>
                            <b-form-group
                                label="Комментарий"
                                class="col-6"
                                label-for="utm_data"
                            >
                                <textarea class="form-control" id="utm_data" v-model="form.note"></textarea>
                            </b-form-group>
                        <b-form-group
                            v-if='badge'
                            label='Статус заявки'
                            class='col-6'
                            label-for='lead_status'
                        >
                            <b-select v-model='form.status'>
                                <b-select-option v-for='status in leadStatuses' :key='status.value' :value='status.value'>
                                    {{ status.text }}
                                </b-select-option>
                            </b-select>
                        </b-form-group>
                        <div v-if="!!form.id" class="col-12 text-right">
                            <b-button class="mr-2" variant="primary" @click="saveLeadData">
                                <b-spinner v-if="isSaving" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
                                <span :class="{ captionPadding: isSaving }">Сохранить данные</span>
                            </b-button>
                            <hr/>
                        </div>
                        <h5 v-if="canSetPromoLesson || !form.status" class="col-12 mb-4">График преподавателей на указаную
                            дату</h5>

                        <div v-if="isLoadingSuggestions" :size="40">
                          <i class="la la-spinner la-spin" v-bind:style="{fontSize: '30px'}"></i> Загрузка данных ...
                        </div>

                        <div class="col-12" v-if="!isLoadingSuggestions && (canSetPromoLesson || !form.status) && LessonDate && LessonTime">
                            <div class="row">
                                <b-form-group class="col-6 col-lg-4" v-for="suggestion in getSuggestionList.best_suggestions" :key="suggestion.id">
                                    <TeacherCard
                                        :teacherName="suggestion.teacher.full_name"
                                        :dateFrom="suggestion.date_from"
                                        :dateEnd="suggestion.date_to"
                                        :id="suggestion.teacher.id"
                                        :lessonTime="LessonTime"
                                        :thebest="true"
                                        class="card_hover"
                                        :currentOption="selectedTeacherOption"
                                        :onSelect="selectTeacherOptionHandle"
                                        :onChange="selectTeacherHandle"
                                    />
                                </b-form-group>
                                <b-form-group class="col-6 col-lg-4" v-for="suggestion in getSuggestionList.other_suggestions" :key="suggestion.id">
                                    <TeacherCard
                                        :teacherName="suggestion.teacher.full_name"
                                        :dateFrom="suggestion.date_from"
                                        :dateEnd="suggestion.date_to"
                                        :id="suggestion.teacher.id"
                                        :lessonTime="LessonTime"
                                        :thebest="false"
                                        class="card_hover"
                                        :currentOption="selectedTeacherOption"
                                        :onSelect="selectTeacherOptionHandle"
                                        :onChange="selectTeacherHandle"
                                    />
                                </b-form-group>
                                <b-form-group class="col-6 col-lg-4">
                                    <AllTeachersCard
                                        :teacherList="getSuggestionList.all_teachers || []"
                                        :currentOption="selectedTeacherOption"
                                        :lessonTime="LessonTime"
                                        :onSelect="selectTeacherOptionHandle"
                                        :onChange="selectTeacherHandle"
                                    />
                                </b-form-group>
                            </div>
                        </div>

                        <div v-else-if='canSetPromoLesson || !form.status' class='col-3'><p class='badge badge-warning d-block'>Сначала выберите дату и время занятия</p></div>
                        <div class="col-6" v-if="getLeadDetails && getLeadDetails.lesson_info && getLeadDetails.lesson_info.teacher && getLeadDetails.lesson_info.status !== 3">
                            <div class="py-4 container lesson-card">
                                <h4>Назначено пробное занятие</h4>
                                <div>Преподаватель: {{ getLeadDetails.lesson_info.teacher.full_name }}</div>
                                <div>Телефон преподавателя: {{getLeadDetails.lesson_info.teacher.phone}}</div>
                                <div>Дата и время занятия: {{ getLeadDetails.lesson_info.date_from | toDate }}</div>
                                <div class="text-right mt-3">
                                    <b-button v-if="getLeadDetails.lesson_datetime.substring(0, 10) !== LessonDate || getLeadDetails.lesson_datetime.substring(11, 16) !== LessonTime" class="mr-2" variant="primary"
                                              @click.prevent="updateDateTime()"
                                              title="Сохранить дату и время">
                                        <span class="fa fa-save"></span> Сохранить дату и время
                                    </b-button>

                                    <b-button class="mr-2" variant="danger"
                                              @click.prevent="cancelPromo(form.id)"
                                              title="Отменить пробный урок">
                                        <span class="fa fa-history delete"></span> Отменить пробное занятие
                                    </b-button>

                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="form.status === 3">
                            <h4 class="info_red">Пробное занятие отменено</h4>
                        </div>
                        <b-form-group class="col-12 text-right">
                            <b-button v-if="form.id && canSetPromoLesson" type="submit" class="mr-2" variant="success">
                                <b-spinner v-if="isAccepting" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
                                <span :class="{ captionPadding: isAccepting }">Записать на пробное</span>
                            </b-button>
                            <b-button v-if="form.id && form.status === 0" type="button" class="mr-2" variant="danger"
                                      @click.prevent="rejectLead">Отклонить заявку
                            </b-button>
                            <b-button v-if="!form.id && !form.status" type="button" @click.prevent="createLeadAndAccept"
                                      class="mr-2" variant="success">
                                <b-spinner v-if="isCreating" style="width: 1.4rem; height: 1.4rem;" label="Sending"></b-spinner>
                                <span :class="{ captionPadding: isCreating }">Сохранить и Записать на пробное</span>
                            </b-button>
                            <b-button @click.prevent="removeLead()" variant="danger" class='mr-2'><i class='fa fa-trash-o mr-2'></i>Удалить</b-button>
                            <b-button @click.prevent="$router.back()" variant="primary">Назад</b-button>
                        </b-form-group>
                    </b-form>
                </div>
            </b-tab>
            <b-tab title='Копии' v-if='copies && this.$route.params.detail'>
                <LeadsTable
                    :leads='copies'
                /></b-tab>
        </b-tabs>
        <Preloader v-if='isLoading'/>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Multiselect from 'vue-multiselect';
    import NormalTimePicker from '../../components/NormalTimePicker';
    import TeacherCard from "../../components/Leads/TeacherCard";
    import DeleteModal from '../../components/Modal/DeleteModal';
    import AllTeachersCard from "../../components/Leads/AllTeachersCard";
    import { debounce } from 'debounce';
    import moment from 'moment';
    import LeadsTable from '@/components/Leads/LeadsTable';
    import Loader from '@/components/Loader/Loader';
    import Preloader from '@/components/Preloader';
    import {leadStatuses} from '@/helpers/badges';
    import notificationMixin from '@/mixins/notificationMixin';

    const MOSCOW_TIMEZONE = 3;

    export default {
        name: 'LeadsPage.vue',
        mixins: [notificationMixin],
        components: { Preloader, LeadsTable, TeacherCard, AllTeachersCard, Multiselect, NormalTimePicker, Loader },
        data() {
            return {
                testtime: '',
                isLoading: false,
                isLoadingSuggestions: false,
                realStatus: null,
                form: {
                    id: null,
                    student_full_name: null,
                    student_age: null,
                    age_group: null,
                    location: null,
                    experience: null,
                    parent_name: null,
                    parent_email: null,
                    parent_phone: null,
                    notifications_way: [],
                    lesson_datetime: null,
                    original_lesson_time: null,
                    timezone: null,
                    status: null,
                    note: null,
                    lesson_id: null,
                    created_at: null,
                    updated_at: null,
                    utm: null,
                    country_code: null,
                },

                originalLessonTime: '',
                LessonDate: '', // отдельно дата урока из lesson_datetime
                LessonTime: '', // отдельно время урока из lesson_datetime
                lessonDuration: 25,

                selectedTeacherOption: null,
                selectedTeacherId: null,
                selectedLessonTime: null,

                // TODO use constants
                badgeMap: leadStatuses,

                isCreating: false,
                isAccepting: false,
                isSaving: false,
                validateForPromo: false
            }
        },
        computed: {
            ...mapGetters({
                getSettingsWorkingHours: 'settings/getSettingsWorkingHours',
                getLocationsList: 'settings/getLocationsList',
                getLeadDetails: 'leads/getLeadDetails',
                getAllTimezones: 'common/getAllTimezones',
                getExperienceList: 'common/getExperienceList',
                getSuggestionList: 'leads/getSuggestionList',
                getNotificationWay: 'common/getNotificationWay',
                getLeadStatuses: 'common/getLeadStatuses',
                getCountriesCodes: 'common/getCountriesCodes',
                getAgeGroups: 'common/getAgeGroups'
            }),
            leadStatuses() {
                const out = [];
                if (this.getLeadStatuses) {
                    if (this.realStatus) {
                        const states = this.getLeadStatuses[this.realStatus]['states']
                        for (const key in states) {
                            out.push({
                                value: key,
                                text: states[key]
                            })
                        }
                    }
                }
                return out
            },
            badge() {
                if (this.realStatus) {
                    return leadStatuses[this.realStatus]
                }
                // Отсутсвует статус если запись создается
                return false;
            },
            isCopy() {
                return this.getLeadDetails?.copied
            },
            copies() {
                if (this.getLeadDetails && this.getLeadDetails.copies && this.getLeadDetails.copies.length > 0) {
                    return this.getLeadDetails.copies
                }
                return null;
            },
            nameValidation() {
                return (!!this.form.student_full_name && this.form.student_full_name.length >= 2) || !this.validateForPromo;
            },
            canSetPromoLesson() {
                if (this.realStatus !== 0 && !this.realStatus) {
                    return false;
                }
                if (this.getLeadDetails && this.getLeadDetails.lesson_info.status && this.getLeadDetails.lesson_info.status > 0) {
                    return false;
                }
                return this.realStatus === 0 || Object.keys(this.getLeadStatuses[0]['states']).includes(this.realStatus.toString());
            },
            ageValidation() {
                return (!!this.form.student_age && this.form.student_age >= 1) || !this.validateForPromo;
            },
            experienceValidation() {
                return (this.form.experience !== null) || !this.validateForPromo;
            },
            parentNameValidation() {
                return (!!this.form.parent_name && this.form.parent_name.length >= 2) || !this.validateForPromo;
            },
            expierenceValidation() {
                return (this.form.experience >= 0) || !this.validateForPromo;
            },
            phoneNumberValidator() {
                return (!!this.form.parent_phone && this.form.parent_phone.length >= 6);
            },
            timezoneValidator() {
                return(!!this.form.timezone) || !this.validateForPromo;
            },
            ageGroupsValidation() {
                return (this.form.age_group !== null);
            },
            validateEmail() {
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return regex.test(this.form.parent_email ? this.form.parent_email.toLowerCase() : false) || !this.validateForPromo
            },
            dateValid() {
                return !!this.LessonDate || !this.validateForPromo;
            },
            timeValidation() {
                return !!this.LessonTime || !this.validateForPromo;
            },
            notificationValidator() {
                if (!this.form.notifications_way) {
                    return false;
                }
                return (this.form.notifications_way.length > 0) || !this.validateForPromo;
            },
            teacherSelected() {
                return (!!this.selectedTeacherId && !!this.selectedLessonTime);
            },
            isDisabled() {
                return !this.canSetPromoLesson;
            },
            isFormValidForSave() {
                const isValid = this.notificationValidator;
                console.error(this.notificationValidator)
                return isValid;
            },
            isFormValid() {
                const isValid = this.nameValidation
                    & this.ageValidation
                    & this.parentNameValidation
                    & this.expierenceValidation
                    & this.phoneNumberValidator
                    & this.notificationValidator
                    & this.teacherSelected
                    & this.timezoneValidator;
                console.error(this.nameValidation
                    , this.ageValidation
                    , this.parentNameValidation
                    , this.expierenceValidation
                    , this.phoneNumberValidator
                    , this.notificationValidator
                    , this.timezoneValidator
                    , this.teacherSelected)
                return isValid;
            }
        },
        watch: {
            // при изменении даты и времени обновляем список вариантов
            LessonDate: debounce(async function (val) {
                if (this.isLoading || this.isLoadingSuggestions)  return;
                console.log('watch LessonDate');
                const date = moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm:ss');
                this.isLoadingSuggestions = true;
                await this.fetchSuggestionList(date);
                this.isLoadingSuggestions = false;
            }, 300),
            LessonTime: debounce(async function (val) {
                if (this.isLoading || this.isLoadingSuggestions)  return;
                console.log('watch LessonTime');
                // конвертируем в местное время
                this.convertUserLocalTimeToMoscowTime();
                // подгружаем варианты
                const date = moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm:ss');
                this.isLoadingSuggestions = true;
                await this.fetchSuggestionList(date);
                this.isLoadingSuggestions = false;
            }, 300),
            '$route.params.detail': async function(val) {
                console.log(val);
                ///await this.fetchDetailLead()
            },
        },
        filters: {
            toDate(value) {
                return moment(value).format('DD.MM.YYYY HH:mm');
            },
            fromUnix(value) {
                return moment.unix(value);
            },
            toUnix(value) {
                return moment(value).unix();
            }
        },
        methods: {
            ...mapActions({
                loadLeadDetails: 'leads/loadLeadDetails',
                fetchSuggestionList: 'leads/fetchSuggestionList',
                sendAndAcceptLead: 'leads/acceptLead',
                createNewLeadAndAccept: 'leads/createNewLeadAndAccept',
                cancelPromoLesson: 'leads/cancelPromoLesson',
                updateLeadData: 'leads/updateLeadData',
                loadLeads: 'leads/loadLeads',
                deleteRecord: 'leads/deleteRecord',
            }),
            selectTeacherOptionHandle(type) {
                this.selectedTeacherOption = type; // какого типа блок выбран (custom или suggestion_ID)
            },
            selectTeacherHandle(payload) {
                this.selectedTeacherId = payload.id;
                this.selectedLessonTime = payload.time;
            },
            convertUserLocalTimeToMoscowTime() {
                const dateTime = `${this.LessonDate} ${this.LessonTime}`;
                if (this.form.timezone) {
                    let timezoneOffset = this.form.timezone.replace('UTC', '').replace('utc', '');
                    if (timezoneOffset) {
                        timezoneOffset = parseInt(timezoneOffset);
                        if (timezoneOffset != MOSCOW_TIMEZONE) {
                            const offset = timezoneOffset - MOSCOW_TIMEZONE;
                            this.originalLessonTime = moment(dateTime).add(offset, 'h').format('HH:mm');
                            return;
                        }
                    }
                    this.originalLessonTime =  moment(dateTime).format('HH:mm');
                }
            },
            rejectLead() {
                this.$modal.show(
                    DeleteModal,
                    {
                        recordId: this.form.id,
                        recordName: this.form.student_full_name,
                        deleteHandler: async (_id) => {
                            await this.rejectRecord(_id);
                        }
                    }, {name: 'DeleteModal'}
                );
            },
            async rejectRecord(id) {
                try {
                    const {data} = await this.$axios.post('/api/leads/remove', {
                        id: id
                    });
                    this.responseNotify(data, "Заявка отклонена")
                    this.$router.back()
                } catch (e) {
                    this.$toasted.error(e.message)
                    console.error(e);
                }
            },
            getFromData(leadOnly = false) {
                // TODO выглядит страшно, что поменять?
                return leadOnly ? {
                    ...this.form,
                    notifications_way: this.form.notifications_way.join(','),
                    original_lesson_time: this.LessonDate && this.originalLessonTime ? moment(`${this.LessonDate} ${this.originalLessonTime}`).format('HH:mm'): null,
                    lesson_datetime: this.LessonDate && this.LessonTime ? moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm:ss'): null,
                } : {
                    ...this.form,
                    lesson_datetime: this.LessonDate && this.LessonTime ? moment(`${this.LessonDate} ${this.LessonTime}`).format('YYYY-MM-DD HH:mm:ss'): null,
                    lesson_duration: this.lessonDuration,
                    notifications_way: this.form.notifications_way.join(','),
                    original_lesson_time: this.LessonDate && this.originalLessonTime ? moment(`${this.LessonDate} ${this.originalLessonTime}`).format('HH:mm'): null,
                    selected_lesson_datetime: this.LessonDate && this.selectedLessonTime ? moment(`${this.LessonDate} ${this.selectedLessonTime}`).format('YYYY-MM-DD HH:mm:ss'): null,
                    teacher_id: this.selectedTeacherId,
                };
            },
            async saveLeadData() {
                try {
                    this.isSaving = true;

                    if (!this.isFormValidForSave) {
                        this.isSaving = false;
                        alert('Заполните "способ связи"');
                        return;
                    }

                    const result = await this.updateLeadData( this.getFromData(true) );

                    if (result.status === 'success') {
                        this.isSaving = false;
                        this.fillLeadData(result.data);
                        this.$toasted.success('Данные обновлены!', {
                            position: 'bottom-right',
                            action: {
                                text: 'Закрыть',
                                onClick: (e, toastObject) => {
                                    toastObject.goAway(0);
                                }
                            }
                        });
                    } else {
                        throw Error(Object.values(result.errors).join("\n"));
                    }
                } catch (e) {
                    this.isSaving = false;
                    this.$toasted.error(`Не удалось сохранить! ${e}`, {
                        position: 'bottom-right',
                        action: {
                            text: 'Закрыть',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        }
                    });
                }
            },
            async acceptLead() {
                this.validateForPromo = true;
                // принятие заявки и создание тестового урока
                if (!this.isFormValid) {
                    this.$toasted.error(`Заполните все обязательные поля`, {position: 'bottom-right'})
                    return;
                }
                try {
                    this.isAccepting = true;
                    const result = await this.sendAndAcceptLead( this.getFromData() );
                    this.responseNotify(result, 'Пробный урок создан!', () => {
                        this.$router.push({name: 'LeadsPage'});
                    })
                } catch (e) {
                    this.isAccepting = false;
                    if (e.response && e.response.data) {
                        this.responseNotify(e.response.data)
                    }
                    console.error(e);
                }
            },
            // создать лид и урок
            async createLeadAndAccept() {
                if (!this.isFormValid) {
                    alert('Заполните все обязательные поля');
                    return;
                }
                try {
                    this.isCreating = true;
                    const result = await this.createNewLeadAndAccept( this.getFromData() );
                    if (result.status === 'success') {
                        this.isCreating = false;
                        this.$toasted.success('Пробный урок создан!', {
                            position: 'bottom-right',
                            action: {
                                text: 'Закрыть',
                                onClick: (e, toastObject) => {
                                    toastObject.goAway(0);
                                }
                            }
                        });
                        this.$router.push({name: 'LeadsPage'});
                    } else {
                        throw Error(Object.values(result.errors).join("\n"));
                    }
                } catch (e) {
                    this.isCreating = false;
                    this.$toasted.error(`Не удалось сохранить! ${e}`, {
                        position: 'bottom-right',
                        action: {
                            text: 'Закрыть',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            }
                        }
                    })
                }
            },
            cancelPromo(id) {
                this.$modal.show(
                    DeleteModal,
                    {
                        recordId: id,
                        title: 'Вы действительно хотите отменить?',
                        recordName: 'Пробное занятие',
                        deleteHandler: async (_id) => {
                            try {
                                const {data} = await this.cancelPromoLesson(_id);
                                this.responseNotify(data, 'Занятие отменено', async () => await this.fetchDetailLead())
                            } catch (e) {
                                if (e.response && e.response.data) {
                                    this.responseNotify(e.response.data, 'Занятие отменено', async () => await this.fetchDetailLead())
                                }
                                console.error(e)
                            }
                            // await this.loadLeads();
                        }
                    }, {name: 'DeleteModal'}
                )
            },
            fillLeadData(leadData) {
                const formKeys = Object.keys(this.form);
                for (const key of Object.keys(leadData)) {
                  if (formKeys.includes(key)) {
                    if (key === 'status') {
                        this.realStatus = leadData[key] ?? null
                        }
                      if (key === 'notifications_way') {
                        console.log(leadData)
                          this.form[key] = leadData[key] ? leadData[key].split(',') : null;
                        } else {
                          this.form[key] = leadData[key] ?? null;
                        }
                    }
                }
                // разделяем дату и время на части
                this.LessonDate = this.form.lesson_datetime ? moment(this.form.lesson_datetime).format('YYYY-MM-DD'): null;
                this.LessonTime = this.form.lesson_datetime ? moment(this.form.lesson_datetime).locale('ru').format('HH:mm'): null;
            },
            async updateDateTime() {
                await this.saveLeadData()
                await this.loadLeadDetails(this.$route.params.detail);
            },
            async fetchDetailLead() {
                this.isLoading = true;
                try {
                    if (this.$route.params.detail) {
                        await this.loadLeadDetails(this.$route.params.detail);
                        this.fillLeadData(this.getLeadDetails);
                    }
                    this.isLoading = false;
                    this.isLoadingSuggestions = true;
                    await this.fetchSuggestionList(this.getLeadDetails ? this.getLeadDetails.lesson_datetime : new Date());
                  this.isLoadingSuggestions = false;
                } catch (e) {
                  console.log(e)
                }
            },
            removeLead() {
                this.$modal.show(
                    DeleteModal,
                    {
                        recordId: this.form.id,
                        recordName: this.form.student_full_name,
                        deleteHandler: async (_id) => {
                            try {
                                const {data} = await this.deleteRecord(_id)
                                this.responseNotify(data, 'Заявка удалена', async () => await this.$router.push('LeadsPage'));
                            } catch (e) {
                                if (e.response && e.response.data) {
                                    this.responseNotify(e.response.data)
                                }
                                console.error(e)
                            }
                        },
                    }, { name: 'DeleteModal' },
                );
            },
        },
        async mounted() {
            await this.fetchDetailLead();
        }
    }
</script>

<style lang="scss" scoped>
    label.form-control {
        display: none;
    }
    .card_hover {
        position: relative;

        &::after {
            z-index: 10;
            position: absolute;
            right: 20px;
            top: 20px;
            display: block;
            width: 20px;
            height: 20px;
            background: red;
        }
    }
    .lesson-card {
        border-radius: 10px;
        background-color: #8cd4b4;
        font-size: 16px;
    }
    .info_red {
        color: #FD5F00;
    }
    .captionPadding {
        margin-left: 10px;
    }
</style>