<template>
    <div class='promocode-page'>
        <h4 class='page-title'>Промокоды <i class='fa fa-ticket' aria-hidden='true'></i></h4>
        <div class='filter__container'>
            <div class='row mb-3 justify-content-between'>
                <TableSearchBar class='col-4' @onSearch='changeQueryParams($event, "search")' />
                <div class='col-4 text-right'>
                    <button class='button_add' @click.prevent='$router.push({name: "PromocodesCreate"})'>
                      Добавить промокод
                    </button>
                </div>
            </div>
            <div class='input-group col-4'>

            </div>
        </div>
        <div class='widget'>
            <table class='table table-hover' v-if='promocodes'>
                <thead class='table_head'>
                <tr>
                    <th>№</th>
                    <th>Название</th>
                    <th>Скидка %</th>
                    <th>Бонус</th>
                    <th>Использован?</th>
                    <th>Начало</th>
                    <th>Окончание</th>
                    <th>Код</th>
                    <th>Многоразовый</th>
                    <th>Для одного номера</th>
                    <th class='text-center actions'>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='promocode in promocodes' :key='promocode.id'>
                    <td>{{ promocode.id }}</td>
                    <td>{{ promocode.title }}</td>
                    <td>{{ promocode.discount }}</td>
                    <td>{{ promocode.profit_description }}</td>
                    <td>{{ promocode.is_used === 1 ? 'Да' : 'Нет' }}</td>
                    <td>{{ promocode.starts_from }}</td>
                    <td>{{ promocode.ends_at }}</td>
                    <td>{{ promocode.code }}</td>
                    <td>{{ promocode.endless === 1 ? 'Да' : 'Нет' }}</td>
                    <td>{{ promocode.is_one_phone === 1 ? 'Да' : 'Нет' }}</td>
                    <td class="d-flex justify-content-center">
                        <router-link class='btn mr-2 btn-primary'
                                     :to="{name: 'PromocodesEdit', params: {id: promocode.id}}">
                            <span class='glyphicon glyphicon-edit'></span>
                        </router-link>
                        <a class='btn btn-danger'
                           @click.prevent='deleteRow(promocode.id, promocode.code)'>
                            <span class='fa fa-trash-o delete'></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class='overflow-auto'>
                <b-pagination class='paginator'
                              v-if='pagination.total'
                              v-model='pagination.current_page'
                              :total-rows='pagination.total'
                              :per-page='pagination.per_page'
                              aria-controls='my-table'
                ></b-pagination>
            </div>

        </div>
    </div>
</template>

<script>
import PaginationMixin from '../../mixins/paginationMixin';
import TableSearchBar from '../../components/SearchBar/TableSearchBar';
import DeleteModal from '../../components/Modal/DeleteModal';

export default {
    name: 'Promocodes',
    components: { TableSearchBar },
    mixins: [PaginationMixin],
    data() {
        return {
            promocodes: null,
        };
    },
    watch: {},
    methods: {
        async updateRouter() {
            await this.$router.push({ query: this.routerParams });
            await this.fetchPromocodes();
        },
        async fetchPromocodes() {
            try {
                const { data } = await this.$axios.get('api/promocode', {
                    params: {
                        ...this.$route.query,
                    },
                });
                if (data && data.data && data.data.length) {
                    this.parsePaginationMeta(data);
                    this.promocodes = data.data;
                }
                return null;
            } catch (e) {
                alert(e);
            }
        },
        deleteRow(id, name, index) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: async (id) => {
                        try {
                            const { data } = await this.$axios.delete('/api/promocode/' + id, { id });
                            this.$toasted.success('Запись удалена!', { position: 'bottom-right' });
                            await this.fetchPromocodes();
                        } catch (e) {
                            const { data } = e.response;
                            this.$toasted.error(data.message, { position: 'bottom-right' });
                        }
                    },
                }, { name: 'DeleteModal' },
            );
        },
    },
    async created() {
        await this.fetchPromocodes();
    },
};
</script>

<style scoped>

</style>