<template>
    <div class='position-relative' v-click-outside='closeMenu' @mouseleave='startCloseCountdown' @mouseenter='clearTimeout'>
        <a class='action-menu' :class='{"active": opened}' @click.prevent='opened = !opened'><span></span></a>
        <div class='menu-container' v-if='opened'>
            <h5 class='menu-header mt-2 mb-2 px-3'>Действия</h5>
            <slot name="menu" :itemClick="itemClick"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RowActionMenu',
    data() {
        return {
            opened: false,
            timeout: null,
        }
    },
    methods: {
        closeMenu() {
            this.opened = false
        },
        clearTimeout() {
            clearInterval(this.timeout)
        },
        startCloseCountdown() {
            this.clearTimeout()
            this.timeout = setTimeout(() => {
                this.closeMenu()
            }, 1500)
        },
        async itemClick() {
            try {
                this.closeMenu();
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>

<style lang='scss' scoped>

.action-menu {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    background: #fefefe;
    //box-shadow: 0px 3px 20px -3px rgba(#000, .08);
    span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: darken(#eee, 40);
        position: relative;
        transition: .2s;
        &::before {
            display: block;
            position: absolute;
            top: -7px;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: darken(#eee, 40);
        }
        &::after {
            display: block;
            position: absolute;
            bottom: -7px;
            content: "";
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: darken(#eee, 40);
        }
    }
    &.active {
        span {
            width: 3px;
            height: 15px;
            transform: rotate(45deg);
            border-radius: 1px;
            z-index: 10;
            &::before {
                display: block;
                position: absolute;
                top: 0px;
                content: "";
                width: 3px;
                height: 15px;
                border-radius: 1px;
                background: darken(#eee, 40);
                transform: rotate(90deg);
            }
            &::after {
                visibility: hidden;
            }
        }
    }
}
.menu-container {
    box-shadow: 0px 3px 40px -3px rgba(#000, .1);
    position: absolute;
    background: #fff;
    //top: 27px;
    //right: 27px;
    right: 0px;
    top: 0px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    z-index: 5;
    padding-bottom: 10px;
}
.menu-item {
    font-weight: 500;
    color: #7e7e7e;
    &:hover {
        text-decoration: none;
        color: #000;
        background: #eee;
    }
}
.menu-header {
    font-weight: 600;
    font-size: 14px;
}
</style>