<template>
    <div>
        <h1 class='page-title'>Все заявки</h1>

        <b-tabs content-class=''>
            <b-tab title='Заявки' lazy active @click='handleTabSelect'>
                <Leads />
            </b-tab>

            <b-tab title='Лиды' lazy @click='handleTabSelect'>
                <TempLeads />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import Leads from '@/components/admin/leads/Leads';
import TempLeads from '@/components/admin/leads/TempLeads';

export default {
    name: 'LeadsList.vue',
    components: {
        Leads,
        TempLeads
    },
    methods: {
        handleTabSelect() {
            if (this.$route.query && Object.keys(this.$route.query).length > 0) {
                this.$router.replace({ query: null });
            }
        }
    }
};

</script>

<style lang='scss'>
.paginator {
    .page-item {
        .page-link {
            border-bottom: 2px solid rgba(#fff, 0);
            background: none;
            border-radius: 0 !important;
            transition: .1s;

            &:hover {
                background: #3173b1;
                color: #fff;
            }
        }

        &.active {
            .page-link {
                transition: .2s;
                border-bottom: 2px solid #3173B1;

                &:hover {
                    background: #3173b1;
                    color: #fff;
                }
            }
        }
    }
}
</style>