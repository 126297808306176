/*
Миксин предназначен для шаблонизации вывода ошибочныъ или успешных запросов
* */
export default {
    methods: {
        responseNotify(response, message, callback = null) {
            if (response.error || (response.status && response.status !== 'success') || response.errors) {
                const message = Array.isArray(response.message) ? response.message[0] : response.message;
                this.$toasted.error('Ошибка: ' + message, {
                    position: 'bottom-right', action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
            }
            if (response.status === 'success' || (Object.keys(response).includes('error') && !response.error)) {
                this.$toasted.success(message, {
                    position: 'bottom-right', action: {
                        text: 'Закрыть',
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                        },
                    },
                });
                if (callback) {
                    callback()
                }
            }
        },
    },
};