<template>
    <div class="teacher_card">
        <div class="py-4 container" :class="{ active: currentOption === myOptionName, card_green: thebest }" @click="onChangeValue">
            <h6>Преподаватель {{ teacherName }}</h6>
            <p>Свободен с: {{ dateFrom | toDate }} до: {{ dateEnd | toDate }}</p>
            Время:
            <NormalTimePicker v-model="pickedTime" id="original_lessontime" :state="dateValid"/>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import NormalTimePicker from '../../components/NormalTimePicker';

    export default {
        name: 'TeacherCard',
        components: { NormalTimePicker },
        props: {
            onSelect: {
                type: Function,
                required: true
            },
            onChange: {
                type: Function,
                required: true
            },
            currentOption: {
                type: String,
                required: false,
                default: null
            },
            lessonTime: {
                type: String,
                required: false,
                default: null
            },
            id: {
                required: true
            },
            teacherName: {
                required: true
            },
            dateFrom: {
                required: true
            },
            dateEnd: {
                required: true
            },
            thebest: {
                required: false
            }
        },
        data() {
            return {
                pickedTime: ''
            }
        },
        watch: {
            pickedTime: function (newTime, oldTime) {
                this.onChangeValue();
            }
        },
        computed: {
            myOptionName() {
                return 'suggestion_' + this.id;
            },
            dateValid() {
                return !!this.pickedTime;
            }
        },
        methods: {
            onChangeValue() {
                this.onSelect(this.myOptionName);
                this.onChange({
                    id: this.id,
                    time: this.pickedTime.substring(0, 5)
                });
            }
        },
        filters: {
            toDate(value) {
                return moment(value).format('HH:mm')
            }
        },
        mounted() {
            if (this.lessonTime) {
                this.pickedTime = this.lessonTime;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .teacher_card {
        display: flex;

        h6 {
            cursor: pointer;
        }
        .container {
            border-radius: 10px;
            padding: 0.375rem 2rem 0.375rem 1rem;
            box-shadow: 4px 0px 25px rgba(#000, 0.1);
            &.card_green {
                background-color: #cee2ce;
            }
            background-color: #e2c6bf;
        }

        .dropdown-menu.show {
            height: 200px !important;
            overflow-y: auto !important;
        }

        .container {
            position: relative;
            border: 1px solid rgba(#000, 0);

            &.active {
                border: 1px solid rgba(#1b8e72, 0.5);

                &::after, &::before {
                    opacity: 1;
                    background: #1b8e72;
                }
            }

            &::after {
                content: "";
                z-index: 10;
                position: absolute;
                right: 20px;
                top: 20px;
                display: block;
                width: 6px;
                height: 2px;
                background: #aaa;
                transform: rotate(45deg);
                opacity: 0;
            }

            &::before {
                content: "";
                z-index: 10;
                position: absolute;
                right: 20px;
                top: 20px;
                display: block;
                width: 15px;
                height: 2px;
                background: #aaa;
                transform: rotate(135deg) translateY(-5px) translateX(-12px);
                opacity: 0;
            }

            transition: .5s;

            &:hover {

                &::before,
                &::after {
                    opacity: 1;
                }
            }
        }
    }
</style>