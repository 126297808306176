<template>
    <table class='table table-hover'>
        <thead class='table_head'>
        <tr>
            <th class='num-col'>№</th>
            <th class='leaddate-col'>Заявка от</th>
            <th class='name-col'>Имя</th>
            <th class='age-group-col'>Возрастная группа</th>
            <!--            <th class='age-col'>Возраст</th>-->
            <!--            <th>Уровень</th>-->
            <th class='parent-col'>Родитель</th>
            <th class='parentnum-col'>Номер родителя</th>
            <th class='notify-col text-center'>Локация</th>
            <th class='notify-col text-center'>Источник</th>
            <th class='lessontime-col'>Занятие</th>
            <th>Тренер</th>
            <th>Комментарий</th>
            <th class='status'>Статус</th>
            <th>Контакт</th>
            <th class='text-center actions' v-if='actionAvailable'></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for='(lead, index) in leads' :key='index'>
            <td>{{ lead.id }}
              <span v-if='lead.copied' class='d-block p-0 text-left badge badge-copy'>копия</span>
              <span v-if='lead.campaign_id' class='d-block p-0 text-left badge badge-copy'>(Кампания: {{lead.campaign_id}})</span></td>
            <td class='text-center'>{{ lead.created_at | normalizeDate }}</td>
            <td>
                <router-link v-if='lead.status !== 4' class='fio__link'
                             :to="{name: 'LeadsDetail', params: {detail: lead.id}}">
                    {{ lead.student_full_name }} <span class='d-block age-col'>Возраст: {{ lead.student_age }}</span>
                </router-link>
                <span v-else class='fio__link fio__link_unactive'>
                    {{ lead.student_full_name }} <span class='d-block age-col'>Возраст: {{ lead.student_age }}</span>
                </span>
            </td>
            <td>{{ getAgeGroupTitle(lead.age_group) }}</td>
            <!--            <td>{{ lead.student_age }}</td>-->
            <!--            <td>{{ expToWords(lead.experience) }}</td>-->
            <td>{{ lead.parent_name }}</td>
            <td>
                <a class='d-block btn-phone' @click='copyPhone'>{{ lead.parent_phone_filtered }}</a>
                <span class='d-block'>{{ normalizeCountryCode(lead.country_code) }}</span>
                <!--                <LeadNotifications :notification-way='lead.notifications_way'/>-->
            </td>
            <td class="text-center">
                <template v-if="lead.location">{{ lead.location }}</template>
                <template v-else>---</template>
            </td>
            <td class="text-center">
              <template v-if="lead.provider">{{ lead.provider }}</template>
              <template v-else>---</template>
            </td>
            <td class='text-center'>
                <span v-if='lead.lesson_datetime'>{{ lead.lesson_datetime | normalizeDate }}</span>
                <span v-else>---</span>
            </td>
            <!--            <td>{{ lead.timezone }}</td>-->
            <td class='text-center'>{{ lead.teacher_name || '---' }}</td>
            <td class='text-center'>
                <ColumnExpand v-if='lead.note' :value='lead.note' />
            </td>
            <td class='text-center'>
                <a @click='contactClient(lead)'>
                    <b-badge :variant='badgeMap[lead.status].css' pill>{{ badgeMap[lead.status].title }}</b-badge>
                </a>
            </td>
            <!--            <td>{{ lead.lesson_datetime | normalizeDate }}</td>-->
            <td class='text-center'>
                <span v-if='lead.contact_date'>{{ lead.contact_date | normalizeCreateDate }}</span>
                <a class='badge btn-call d-block' title="Обновить дату контакта" v-if='lastContactSave' @click='lastContactSave(lead.id, null)'>обновить</a>
            </td>
            <td class='actions_container justify-content-start' v-if='actionAvailable'>
                <RowActionMenu :key='lead.id'>
                    <template v-slot:menu='{itemClick}'>
                        <router-link v-show='lead.status !== 4' :to='{name: "LeadsDetail", params: {detail: lead.id}}' class='menu-item px-3 py-2'><i class='fa fa-eye mr-2'></i>Детали заявки</router-link>
                        <a v-show='cloneLead && lead.status !== 4' class='menu-item px-3 py-2' @click.prevent='cloneLead(lead.id, lead).then(itemClick)'><i class='fa fa-clone mr-2'></i>Повторить заявку</a>
                        <a v-show='lead.status === 4 && restoreLead' class='menu-item px-3 py-2' @click.prevent='restoreLead(lead.id).then(itemClick)'><i class='fa fa-history mr-2'></i>Восстановить заявку</a>
                        <a v-show='lead.status !== 4 && deleteRow' class='menu-item text-danger px-3 py-2' @click.prevent='deleteRow(lead.id, lead.student_full_name, index)'><i class='fa fa-trash mr-2'></i>Удалить</a>
                    </template>
                </RowActionMenu>
            </td>
<!--            <td class='actions_container justify-content-start' v-if='actionAvailable'>-->
<!--                &lt;!&ndash;                <router-link v-if='lead.status !== 4' class='btn mr-2 btn-primary'&ndash;&gt;-->
<!--                &lt;!&ndash;                             :to="{name: 'LeadsDetail', params: {detail: lead.id}}">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <span class='glyphicon glyphicon-eye-open'></span>&ndash;&gt;-->
<!--                &lt;!&ndash;                </router-link>&ndash;&gt;-->
<!--                <a v-if='(lead.status === 0 || lead.status === 2) && deleteRow' class='btn mr-2 btn-danger'-->
<!--                   @click.prevent='deleteRow(lead.id, lead.student_full_name, index)'>-->
<!--                    <span class='fa fa-trash-o delete'></span>-->
<!--                </a>-->
<!--                <a class='btn mr-2 btn-primary'-->
<!--                   v-if='cloneLead'-->
<!--                   @click.prevent='cloneLead(lead.id)'-->
<!--                   title='Повторить заявку'>-->
<!--                    <span class='fa fa-clone delete'></span>-->
<!--                </a>-->
<!--                <a v-if='lead.status === 4 && restoreLead' class='btn mr-2 btn-primary'-->
<!--                   @click.prevent='restoreLead(lead.id)'-->
<!--                   title='Восстановить заявку'>-->
<!--                    <span class='fa fa-history delete'></span>-->
<!--                </a>-->
<!--            </td>-->
        </tr>
        </tbody>
    </table>
</template>

<script>
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import { mapGetters } from 'vuex';
import duration from 'dayjs/plugin/duration';
import LeadNotifications from '@/components/Leads/LeadNotifications';
import { leadStatuses } from '@/helpers/badges';
import { lessonStatuses } from '@/helpers/badges';
import LeadContactModal from '@/components/Leads/LeadContactModal';
import ColumnExpand from '@/components/Leads/ColumnExpand';
import RowActionMenu from '@/components/Leads/RowActionMenu.vue';

export default {
    name: 'LeadsTable',
    components: { RowActionMenu, ColumnExpand, LeadNotifications },
    props: {
        leads: {
            type: Array,
            required: true,
        },
        cancelPromo: {
            type: Function,
        },
        deleteRow: {
            type: Function,
        },
        cloneLead: {
            type: Function,
        },
        restoreLead: {
            type: Function,
        },
        lastContactSave: {
            type: Function,
        },
    },
    data() {
        return {
            badgeMap: leadStatuses,
            lessonStatuses,
        };
    },
    computed: {
        ...mapGetters({
            getExperienceList: 'common/getExperienceList',
            countriesMap: 'common/getCountriesCodesMap',
            getAgeGroups: 'common/getAgeGroups'
        }),
        actionAvailable() {
            return !!(this.cancelPromo || this.cloneLead);

        },
    },
    filters: {
        normalizeDate(date) {
            return dayjs(date).format('DD.MM.YYYY dd HH:mm');
        },
        normalizeCreateDate(date) {
            return dayjs(date).format('DD.MM.YYYY HH:mm');
        }
    },
    methods: {
        expToWords(exp) {
            return this.getExperienceList[exp].text;
        },
        getAgeGroupTitle(age_group) {
            let result = null
            this.getAgeGroups.forEach(item => {
                if (item.value === age_group) {
                    result = item.text
                }
            })

            return result
        },
        normalizeCountryCode(code) {
            if (this.countriesMap[code]) {
                return this.countriesMap[code]
            }
            return;
        },
        copyPhone(evt) {
            navigator.clipboard.writeText(evt.target.text)
            this.$toasted.info('скопировано в буффер обмена', {
                position: "bottom-right",
                duration: 1500
            })
        },
        contactClient(item) {
            if (this.lastContactSave) {
                this.$modal.show(
                    LeadContactModal,
                    {
                        record: item,
                        saveFunc: this.lastContactSave,
                    },
                    {
                        height: 'auto',
                        maxHeight: 900,
                    },
                );
            }
        },
    },
    created() {
        dayjs.extend(duration);
        dayjs.locale(ru);
    },
};
</script>

<style lang='scss' scoped>
.table_head th {
    font-size: 12px;
    text-align: center;

    &:first-child {
        text-align: left;
    }
}

.table {
    td {
        word-break: break-word;
    }
}

.name-col {
    width: 200px;
    text-align: left !important;
}

.parent-col {
    width: 150px;
    text-align: left !important;
}

.parentnum-col {
    width: 150px;
}

.notify-col {
    width: 90px;
}

.age-col {
    font-size: 13px;
    color: lighten(#000, 40);
}

.fio__link {
    text-decoration: underline;
    text-decoration-color: lighten(#000, 60);
    text-decoration-style: dotted;
    color: black;
    font-weight: 500;

    &:hover {
        color: #3173B1;
    }

    &_unactive {
        &:hover {
            color: black;
        }
    }
}

.actions {
    width: 50px;
}

.status {
    width: 150px;
}

.num-col {
    width: 65px;
}

.leaddate-col {
    width: 100px;
    text-align: left !important;
}

.lessontime-col {
    width: 100px;
}

.badge {
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    width: 100%;

    &-skipped__promo {
        background: orange;
    }

    &-controll {
        background: rebeccapurple;
    }
}

.btn-call {
    border: 1px dotted darkseagreen;
}
.btn-phone {
    color: #000;
}
</style>