<template>
    <div>
        <p class='expand'>{{ text }} <a v-if='value.length > this.limit' class='badge' @click.prevent='expand = !expand'>{{ !expand ? 'полностью' : 'скрыть'}}</a></p>
    </div>
</template>

<script>

export default {
    name: 'ColumnExpand',
    props: {
        limit: {
            type: Number,
            default: 32
        },
        value: [String]
    },
    data() {
        return {
            expand: false,
        };
    },
    computed: {
        text() {
            return this.expand ? this.value : this.value.substring(0, this.limit);
        }
    }
};
</script>

<style lang='scss' scoped>
.table_head th {
    font-size: 12px;
    text-align: center;

    &:first-child {
        text-align: left;
    }
}

.name-col {
    width: 200px;
}

.parent-col {
    width: 150px;
}

.parentnum-col {
    width: 150px;
}

.notify-col {
    width: 50px;
}

.age-col {
    font-size: 13px;
    color: lighten(#000, 40);
}

.fio__link {
    text-decoration: underline;
    text-decoration-color: lighten(#000, 60);
    text-decoration-style: dotted;
    color: black;
    font-weight: 500;

    &:hover {
        color: #3173B1;
    }
}

.actions {
    width: 170px;
}

.status {
    width: 150px;
}

.num-col {
    width: 65px;
}

.leaddate-col {
    width: 130px;
}

.lessontime-col {
    width: 170px;
}

.badge {
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;

    &-skipped__promo {
        background: orange;
    }

    &-controll {
        background: rebeccapurple;
    }
}

.btn-call {
    border: 1px dotted darkseagreen;
}

.expand {
    word-break: break-word;
}
//
</style>