/*
* Данный миксин работает только с таким компонентом можно его смело вырезать и комментария и вставить в нужном месте
* Также необходимо переопеределить метод updateRouter() и спредом this.$router.push({ query: this.routerParams });

<b-pagination class='paginator'
    v-if='pagination.total'
    v-model='pagination.current_page'
    :total-rows='pagination.total'
    :per-page='pagination.per_page'
    aria-controls='my-table'
></b-pagination>

* parsePaginationMeta(response) - позволяет доставть данные о пагинации из стандартного laravel ответ paginate
* метод выступает в качестве хелпера.
*
* changeQuery(value, type) - метод для записи данных в routeParams который по идее нужно распаковать в методе updateRouter
* см. первый абзац
*
* */
export default {
    data() {
        return {
            routerParams: {},
            pagination: {
                current_page: +this.$route.query.page,
                total: null,
                per_page: null,
            },
        }
    },
    watch: {
        'pagination.current_page': function(val) {
            this.changeQueryParams(val, 'page')
        },
    },
    methods: {
        parsePaginationMeta(response) {
            const reqKeys = ['total', 'per_page', 'current_page'];
            for (const key of reqKeys) {
                this.pagination[key] = response[key];
            }
        },

        changeQueryParams(value, type) {
            this.routerParams[type] = value;
            this.updateRouter();
        },

        updateRouter() {
            throw new Error("METHOD UPDATE ROUTER NOT IMPLEMENTED")
        },

    },
}